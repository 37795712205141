import React, { useState, useEffect } from "react";
import Header from "../generalPages/directives/header";
import Footer from "../generalPages/directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/generalnavbar.css";
import "./generalCSS/blog.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Vortex } from "react-loader-spinner";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  getBlogDetailAction,
  getBlogCommentsAction,
  insertIntoBlogCommentAction,
} from "../Action/user.action";
import moment from "moment";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import config from "../config";
import { toast, Toaster } from "react-hot-toast";

const Blogdetail = () => {
  const { id } = useParams();

  const [blogDetailsByid, setBlogDetailsByid] = useState([]);
  const [blogDetailsByidRecent, setBlogDetailsByidRecent] = useState([]);
  const [recentComments, setRecentComment] = useState([]);
  const [commentsData, setCommentData] = useState({
    comment: "",
    userName: "",
    userEmail: "",
    website: "",
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getMoreSuggestionAPI();
    getRecentComments();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const getMoreSuggestionAPI = async () => {
    let res = await getBlogDetailAction({ id: id });
    if (res.success) {
      setBlogDetailsByid(res.data);
      setBlogDetailsByidRecent(res.getRecentBlogsDetails);
    }
  };

  const getRecentComments = async () => {
    let res = await getBlogCommentsAction();
    if (res.success) {
      res.data.length = 3;
      setRecentComment(res.data);
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setCommentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // **Validation Function**
  const validateForm = () => {
    let tempErrors = {};
    let validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (!commentsData.userName.trim()) {
      tempErrors.userName = "Name is required";
    }
    if (!commentsData.userEmail.trim()) {
      tempErrors.userEmail = "Email is required";
    } else if (!validEmailRegex.test(commentsData.userEmail)) {
      tempErrors.userEmail = "Enter a valid email address";
    }
    if (!commentsData.comment.trim()) {
      tempErrors.comment = "Comment cannot be empty";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // Return true if no errors
  };

  const submitHandler = async () => {
    if (!validateForm()) return; // Stop if validation fails

    try {
      let commentDataToSend = { ...commentsData, blogId: id };
      let insertComment = await insertIntoBlogCommentAction(commentDataToSend);

      if (insertComment.success) {
        toast.success(insertComment.msg);

        // **Update comments list without reloading the page**
        setRecentComment((prev) => [
          {
            userName: commentsData.userName,
            comment: commentsData.comment,
            dateTime: moment().format("YYYY-MM-DD"),
          },
          ...prev,
        ]);

        // **Clear form after successful submission**
        setCommentData({
          comment: "",
          userName: "",
          userEmail: "",
          website: "",
        });

        setErrors({});
      } else {
        toast.error(insertComment.msg);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Header />

      <Toaster />
      <section>
        <Container>
          {loading ? (
            <div className="loader-container">
              <Vortex
                visible={true}
                height="120"
                width="120"
                ariaLabel="vortex-loading"
                wrapperStyle={{}}
                wrapperClass="vortex-wrapper"
                colors={[
                  "brown",
                  "brown",
                  "#E46422",
                  "#E46422",
                  "#E46422",
                  "brown",
                ]}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </div>
          ) : (<Row>
            <Col lg={8}>
              <img
                src={blogDetailsByid.image}
                height="auto"
                width="100%"
                style={{ objectFit: "contain" }}
                className="rounded"
              />
              <br />
              <br />
              <p className="blogsmall">
                {blogDetailsByid.dateTime
                  ? moment(blogDetailsByid.dateTime).format("MMMM D, YYYY")
                  : "Date not available"}{" "}
                Posted by: {blogDetailsByid.authorName || ""}
              </p>

              <br />
              <div className="blogpoint">
                <h4 className="" style={{ color: "#E46422" }}>
                  {blogDetailsByid.title}
                </h4>
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogDetailsByid?.description,
                    }}
                  ></div>
                </div>
              </div>

              <div className="blog-comment mt-5">
                <h4> Leave a Comment:</h4>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Control
                      as="textarea"
                      style={{ height: 200 }}
                      placeholder="Your Comment"
                      rows={3}
                      name="comment"
                      value={commentsData.comment}
                      onChange={inputHandler}
                    />
                    {errors.comment && <span className="text-danger">{errors.comment}</span>}
                  </Form.Group>
                </Form>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Your Name"
                        name="userName"
                        value={commentsData.userName}
                        onChange={inputHandler}
                      />
                      {errors.userName && <span className="text-danger">{errors.userName}</span>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Your Email"
                        name="userEmail"
                        value={commentsData.userEmail}
                        onChange={inputHandler}
                      />
                      {errors.userEmail && <span className="text-danger">{errors.userEmail}</span>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Website"
                        name="website"
                        value={commentsData.website}
                        onChange={inputHandler}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button className="w-auto px-5 mt-3 comment-submit" onClick={submitHandler}>
                  Submit
                </Button>
              </div>

              <div className="recent-comments mt-5 w-auto">
                <h4 className="mb-5">Recent Comments</h4>
                {recentComments && recentComments.length > 0 ? (
                  recentComments.map((item, index) => (
                    <Row key={index} className="my-3 comment-box py-3">
                      <Col
                        className="d-flex justify-content-center flex-column align-items-center gap-2"
                        lg={2}
                      >
                        <img
                          src="/assets/images/speech-bubble.png"
                          style={{
                            width: 40,
                            height: 40,
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </Col>
                      <Col lg={10}>
                        <h5 className="text-left comment-name">{item.userName}</h5>
                        <p className="comment-comment mb-0">{item.comment}</p>
                        <small className="comment-date">
                          {moment(item.dateTime).format("YYYY-MM-DD")}
                        </small>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <p className="text-muted">No comments available.</p>
                )}
              </div>

            </Col>

            <Col lg={4}>
              <div className="about-author mt-4">
                <h5>About Author</h5>
                <p>{blogDetailsByid.authorDetail}</p>
              </div>
              <br />
              <div className="recent-blogs mt-4">
                <h5 className="fw-bold fs-4">Recent Blogs</h5>
                {blogDetailsByidRecent &&
                  blogDetailsByidRecent.map((item) => (
                    <Row className="mt-2">
                      <Col lg={3}>
                      <a
                          href={`${config.baseurl}blog_detail/${item.blogId}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                        <img
                          src={item.image}
                          style={{ objectFit: "cover" }}
                          className="rounded"
                          alt=""
                        />
                        </a>
                      </Col>
                      <Col lg={8}>
                        <a
                          href={`${config.baseurl}blog_detail/${item.blogId}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {item.title}
                        </a>
                        <p className="recent-blog-date">
                          {moment(item.dateTime).format("YYYY-MM-DD")}
                        </p>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
          </Row>)}

        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Blogdetail;
