import { useState } from 'react';
import './generalCSS/home.css'
import './generalCSS/generalnavbar.css'
import './generalCSS/seolanding.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import RegisterForm from './registerForm'
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { Container, Row, Col, Button, Card, Modal } from 'react-bootstrap';
const Seolanding = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>

      <div className='seopage'>
        <header className='seoheader'>
          <Container>
            <Row className='align-items-end'>
              <Col lg={7}>
                <div className=''>
                  <img src='assets/images/logo/logo-white.png' width="170px" />
                </div>
              </Col>
              <Col lg={5} className='text-right'>
                <div className="emailContact">
                  <ul>
                    <li><a href="mailto:pr@propertynu.com" className='text-decoration-none'> <span style={{ color: 'white' }}> <b> </b> <FaEnvelope /> pr@propertynu.com </span></a></li>
                    <li><a href="tel:+592 713 2854" className='text-decoration-none'> <span style={{ color: 'white' }}> <b> </b> <FaPhoneAlt /> +(592) 713 2854</span> </a></li>
                  </ul>
                  {/* <span style={{color:'white'}}> <b> </b> <FaEnvelope /> pr@propertynu.com &nbsp;&nbsp; <FaPhoneAlt /> +(592) 713 2854</span> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <section className='banner-area banner-area-two position-relative' id="section-1" >
          <div className='innerbanner'>
            <img src='assets/images/set-designer-work-indoors.jpg' />
          </div>
          <Container>
            <Row className='align-items-center'>
              <Col lg={7}>
                <div className='text-left banner-content'>
                  <h1 className='text-primary text-uppercase mb-4' style={{ lineHeight: "1.3", textShadow: "rgba(255, 255, 255, 0.8) 0px 1px 0px" }}> <b>Boost your bookings, build your business</b></h1>
                  <h5 className='text-white mb-4' style={{ lineHeight: "1.5", textAlign: "" }}>Are you a property owner looking for a hassle-free way to manage your real estate and transport investments?<br /> Look no further! PropertyNU is here to revolutionize your property management experience with our cutting edge property management software.</h5>
                </div>
              </Col>
              <Col lg={5}>
                <Card className='p-4 sameheight' >
                  <RegisterForm />
                </Card>
              </Col>
            </Row>
          </Container>

        </section>
        <section className='Aboutus'>
          <Container>
            <Row>
              <Col lg={6} className=''>
                <div className='text-left box-height'>
                  <div className='img-text'>
                    <div >
                      <h3 className='text-primary'><b>About us</b></h3>
                      <div class="section-title-line"> </div>
                      <div className='about-content'>
                        <p>Propertynu is a revolutionary platform that allows property owners and renters to connect, buy, sell and manage their real estate in a secure, interactive, scalable and cost-effective way. Our innovative blockchain technology reduces the need for middlemen, making it easier for people to have a seamless, intuitive experience. </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} className=''>
                <div className='propertyImage'>
                  <img src='assets/images/propertycar.jpg' className='img1' />
                  <img src='assets/images/property2.svg' className='img2' />
                </div>
              </Col>
            </Row>
          </Container>

        </section>

        <section className='whychooseus pt-5 pb-5 bg-light-grey'>
          <Container>
            <Row>
              <Col lg={12}>
                <h3 className='text-primary'><b>Why Choose Us ?</b></h3>
                <div class="section-title-line"> </div>
                <p>At Propertynu, we stand out as the premier choice for your real estate and property
                  management needs. Our commitment to innovation means you'll enjoy the benefits of
                  cutting-edge technology, making your life more convenient and secure.</p>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>01</b></h1>
                  <p>Security of all information stored on the
                    platform.</p>

                </Card>

              </Col>

              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>02</b></h1>
                  <p>Access to various types
                    of service providers &
                    wider market audience.</p>

                </Card>
              </Col>
              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>03</b></h1>
                  <p>An improved way of interacting with customers,
                    property owners, or service
                    providers.</p>

                </Card>
              </Col>
              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>04</b></h1>
                  <p>Customers can write a
                    review on the places
                    they have stayed.</p>

                </Card>

              </Col>

              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>05</b></h1>
                  <p>Receive direct payment
                    on the property
                    (conditions apply).</p>

                </Card>
              </Col>
              <Col lg={4} className='mb-4'>
                <Card className='p-4'>
                  <h1 className="text-primary"><b>06</b></h1>
                  <p>Seamless management
                    of property with our
                    user-friendly platform.</p>

                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='cta pt-5'>
          <Container>
            <Row className='justify-content-center text-center'>
              <Col lg={8}>
                <h4 className=''>Don’t miss out on the opportunity to simplify your property management journey with PropertyNU.</h4>
                <Button variant='primary' className='rounded-5 mt-3 px-3' onClick={handleShow}>Join our growing community now</Button>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <footer className="pt-3 pb-0 seofooter">
        <Container >
          <div className='copyright'>
            <Row className='align-items-center'>
              <Col lg={4}>
                <div className='social-icon'>
                  <ul>
                    <li><a target='_blank' href="https://www.facebook.com/Propertynugy"><img src='assets/images/icon/facebook2.svg' /></a></li>
                    <li><a target='_blank' href="https://twitter.com/Propertynugy"><img src='assets/images/icon/twitter.svg' /></a></li>
                    <li><a target='_blank' href="https://www.instagram.com/Propertynugy"><img src='assets/images/icon/instagram.svg' /></a></li>
                    <li><a target='_blank' href="https://www.youtube.com/@Propertynugy"><img src='assets/images/icon/youtube.svg' /></a></li>
                  </ul>
                </div>
              </Col>
              <Col lg={4} className='mb-3 text-center'>
                <div>
                  <div className='text-white'>Copyright ©2023 www.propertynu.com </div>
                </div>
              </Col>
              <Col lg={4} className='mb-3 text-right'>
                <div className='mbl-center'>
                  {/* <div className='text-white'><div>Designed by : <a href='https://propertynu.com' target='_blank'>
                                    PropertyNU</a> </div></div> */}
                  <div>Copyright &copy; {new Date().getFullYear()} Property NU All Rights Reserved.</div>

                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>

      <Modal backdrop={false} show={show} onHide={handleClose} className='register-modal'>
        <Modal.Header closeButton className='border-0'>
        </Modal.Header>
        <Modal.Body>
          <RegisterForm />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Seolanding