import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Dropdown, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../../commanComponents/usernavbar"
import config from "../../../../config";
import "../../css/staffdashboard.css"
import "../../css/finance.css"
import { getPurchaseOrderList, deletePurchaseOrder } from '../../../../Action/propertyOwner.action'
import { toast, Toaster } from "react-hot-toast";
import ListMenuButton from "./listMenu";
import UserNameAndTab from "./UserNameAndTab";
import { GoPencil } from "react-icons/go";


const PurchaseOrderList = () => {
    const { id } = useParams()

    const navigate = useNavigate();

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add('staffbody');

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove('staffbody');
        };
    }, []);


    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: '#E46422',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };



    const [order, setOrder] = useState([]);
    const [allOrderList, setAllOrderList] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')


    useEffect(() => {
        fetchOrderList();
    }, []);


 useEffect(() => {
    let filteredData = allOrderList;

    if (fromDate || toDate) {
        filteredData = filteredData.filter(item => {
            const orderDate = new Date(item.purchaseOrderDate);
            const fromdate = fromDate ? new Date(fromDate) : null;
            const todate = toDate ? new Date(toDate) : null;

            if (fromdate) {
                fromdate.setHours(0, 0, 0, 0); // Set to start of the day
            }
            if (todate) {
                todate.setHours(23, 59, 59, 999); // Set to end of the day
            }

            if (fromdate && todate) {
                return orderDate >= fromdate && orderDate <= todate;
            } else if (fromdate) {
                return orderDate >= fromdate;
            } else if (todate) {
                return orderDate <= todate;
            }
            return true;
        });
    }

    if (filterText) {
        const lowerCaseFilterText = filterText.toLowerCase();
        filteredData = filteredData.filter(item =>
            item.supplierName && item.supplierName.toLowerCase().includes(lowerCaseFilterText)
        );
    }

    setOrder(filteredData);
}, [fromDate, toDate, filterText]);




    const fetchOrderList = async () => {
        let res = await getPurchaseOrderList({ type: 1 });
        if (res.success) {
            setOrder(res.data || []);
            setAllOrderList(res.data || [])
        }
    }

    const deleteOrder = async (data) => {
        try {
            let res = await deletePurchaseOrder({ id: data.id })
            if (res.success) {
                toast.success(res.msg)
                fetchOrderList()
            } else {
                toast.error(res.msg)
            }
        } catch (error) {

        }
    }

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
            width: "8%"
        },
        {
            name: 'Supplier Name',
            selector: row => row.supplierName,
            width: "16%",
        },
        {
            name: 'Purchase Order No.',
            selector: row => row.orderNo,
            width: "16%",
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: "16%",
        },
        {
            name: 'Purchase Order Date',
            width: "16.5%",
            selector: row => {
                const date = new Date(row.purchaseOrderDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
            },
        },
        {
            name: 'Amount',
            selector: row => `$${(row.categoryAmountTotal + row.itemAmountTotal).toLocaleString()}`,
            width: "8%",
        },
        // {
        //     name: 'Action',
        //     selector: row => (
        //       <>

        //         <Button variant={`primary`}
        //           className="btn-sm h-auto  rounded-5 " onClick={() => deleteOrder(row)}>

        //           &nbsp;Delete
        //         </Button>
        //         &nbsp;
        //         <Button variant={`primary`}
        //           className="btn-sm h-auto  rounded-5 " onClick={() => navigate(`${config.baseurl}editpurchaseorder/${row.id}`)}>
        //           &nbsp;Edit
        //         </Button>


        //       </>
        //     ),
        // },

        {
            name: 'Action',
            width: "18.5%",
            selector: row => (
                <>
                    <Button variant={`primary`}
                        className="btn-sm h-auto  rounded-5 " onClick={() => navigate(`${config.baseurl}editpurchaseorder/${row.id}`)}>
                        <GoPencil />
                        &nbsp;Edit
                    </Button>
                    &nbsp;
                    {/* <Button variant={`primary`}
                                className="btn-sm h-auto  rounded-5 " onClick={() => deleteExpense(row)}>
        
                                &nbsp;Delete
                            </Button> */}
                    &nbsp;
                    <div className="d-inline-block">


                        <Dropdown >
                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" className="btn-sm h-auto rounded-5 box-shadow-none"></Dropdown.Toggle>

                            <Dropdown.Menu>
                                {/* <Dropdown.Item onClick={() => updateStatus(row, '1')}>Paid</Dropdown.Item>
                                        <Dropdown.Item onClick={() => updateStatus(row, '2')}>Unpaid</Dropdown.Item>
                                        <Dropdown.Item onClick={() => updateStatus(row, '3')}>Overdue</Dropdown.Item> */}
                                <Dropdown.Item onClick={() => deleteOrder(row)}>Delete</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>

                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />

                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className=""><h4 className="mb-0 fw-bolder">Purchase Order </h4></div>


                                    </div>
                                    <Row>

                                        <Col lg={12} className="text-end pull-right">


                                            <Link to={`${config.baseurl}addpurchaseorder`}>
                                                <Button
                                                    variant="primary"
                                                    className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
                                                //onClick={setIsModelOpen}

                                                >
                                                    New
                                                </Button>
                                            </Link>

                                        </Col>
                                        <Col lg={12}>
                                            <Form.Group
                                                className="me-2"
                                                controlId="formBasicPassword"
                                            >

                                                <Row className="mt-2">

                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Search..."
                                                                value={filterText}
                                                                onChange={(e) => setFilterText(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small"></span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="date"
                                                                placeholder="Date"
                                                                aria-label="firstName"
                                                                name="Date"
                                                                onChange={(e) => setFromDate(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small">From</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} className="mb-4">
                                                        <div className="position-relative">
                                                            <Form.Control
                                                                type="date"
                                                                placeholder="Date"
                                                                aria-label="firstName"
                                                                name="Date"
                                                                onChange={(e) => setToDate(e.target.value)}
                                                            />
                                                            <span className="text-light-grey inner-field small">To</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={3}>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {order.length > 0 ?
                                            <Row className="">
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable
                                                            columns={columns}
                                                            data={order}
                                                            customStyles={customStyles}
                                                            theme="solarized"

                                                        />

                                                    </div>
                                                </Col>

                                            </Row>
                                            :
                                            <div className="text-center">
                                                <div>
                                                    <img src="images/nodatafound.svg" />
                                                </div>
                                                <h5 className="mb-1">Purchase Order Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        }

                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                </Container>
            </section>
        </div >
    );
};

export default PurchaseOrderList;
