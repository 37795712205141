import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form, Modal, Table } from "react-bootstrap";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from "react-router-dom";
import config from "../../../config";
import Header from "../../commanComponents/usernavbar";
import "../css/staffdashboard.css";
import "../css/finance.css";
import { getJournalEntryList } from '../../../Action/propertyOwner.action';
import { toast, Toaster } from "react-hot-toast";

import ListMenuButton from "./VehicleListMenu";
import UserNameAndTab from "../Dashboard/Finance/UserNameAndTab";

const VehicleJournalEntry = () => {
  useEffect(() => {
    document.body.classList.add('staffbody');
    return () => {
      document.body.classList.remove('staffbody');
    };
  }, []);

  createTheme('solarized', {
    text: {
      primary: '#000',
      secondary: '#2aa198',
    },
    background: {
      default: '',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#EBEBEB',
    },
    action: {
      button: '#E46422',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)',
    },
  }, 'dark');

  const customStyles = {
    rows: {
      style: {
        minHeight: '70px',
      },
    },
    headCells: {
      style: {
        minHeight: '60px',
        paddingLeft: '20px',
        paddingRight: '20px',
        background: '#FFF8F4',
      },
    },
    cells: {
      style: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
  };

  const [expenseList, setExpenseList] = useState([]);
  const [allexpenseList, setAllExpenseList] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

  useEffect(() => {
    fetchExpenseList();
  }, []);

    useEffect(() => {
      let filteredData = allexpenseList;
  
      if (fromDate || toDate) {
        filteredData = filteredData.filter(item => {
          const orderDate = new Date(item.date);
          const fromdate = fromDate ? new Date(fromDate) : null;
          const todate = toDate ? new Date(toDate) : null;
  
          if (fromdate) {
            fromdate.setHours(0, 0, 0, 0); 
          }
          if (todate) {
            todate.setHours(23, 59, 59, 999);
          }
  
          if (fromdate && todate) {
            return orderDate >= fromdate && orderDate <= todate;
          } else if (fromdate) {
            return orderDate >= fromdate;
          } else if (todate) {
            return orderDate <= todate;
          }
          return true;
        });
      }
  
      setExpenseList(filteredData);
    }, [fromDate, toDate]);

  const fetchExpenseList = async () => {
    let res = await getJournalEntryList({ type: 2 });
    if (res.success) {
      setExpenseList(res.data || []);
      setAllExpenseList(res.data || []);
    }
  };

  const handleViewClick = (entry) => {
    setSelectedEntry(entry);
    setIsModelOpen(true);
  };

  const columns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: 'Entry Number',
      selector: row => row.entryNo,
    },
    {
      name: "Date",
      selector: (row) => {
        const date = new Date(row.date);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    {
      name: 'Action',
      selector: row => (
        <Button
          variant={`primary`}
          className="btn-sm h-auto rounded-5"
          onClick={() => handleViewClick(row)}
        >
          View
        </Button>
      ),
    },
  ];

  const renderJournalEntryDetails = () => {
    if (!selectedEntry || !selectedEntry.journalEntryArr) return null;

    const journalEntries = selectedEntry.journalEntryArr;

    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Account</th>
            <th>Description</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {journalEntries.map((entry, index) => (
            <tr key={index}>
              <td>{entry.account}</td>
              <td>{entry.description}</td>
              <td>{entry.debit || '-'}</td>
              <td>{entry.credit || '-'}</td>
              <td>{entry.name}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="staffdashboard bg-pink">
      <Header />
      <Toaster />
      <section className="listing pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <UserNameAndTab />

          <div className="listMenuButton">
            <Col xl={12} lg={12} className="mb-3">
              <Card className="border-0 tablistfinance">
                <Card.Body>
                  <ListMenuButton />
                </Card.Body>
              </Card>
            </Col>
          </div>

          <div>
            <Col lg={12} className="mb-3">
              <Card className="border-0 bg-white p-3">
                <Card.Header className="border-0 mb-0 pb-0">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <h4 className="mb-0 fw-bolder">Journal Entry</h4>
                    <Link to={`${config.baseurl}addvehiclejournalentry`}>
                      <Button
                        variant="primary"
                        className="rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn text-white"
                      >
                        Add
                      </Button>
                    </Link>
                  </div>
                  <Col lg={12}>
                    <Form.Group
                      className="me-2"
                      controlId="formBasicPassword"
                    >

                      <Row className="mt-2">

                        <Col lg={3} className="mb-4">
                          <div className="position-relative">
                            <Form.Control
                              type="date"
                              placeholder="Date"
                              aria-label="firstName"
                              name="Date"
                              onChange={(e) => setFromDate(e.target.value)}
                            />
                            <span className="text-light-grey inner-field small">From</span>
                          </div>
                        </Col>
                        <Col lg={3} className="mb-4">
                          <div className="position-relative">
                            <Form.Control
                              type="date"
                              placeholder="Date"
                              aria-label="firstName"
                              name="Date"
                              onChange={(e) => setToDate(e.target.value)}
                            />
                            <span className="text-light-grey inner-field small">To</span>
                          </div>
                        </Col>

                        <Col lg={3}>
                        </Col>
                        <Col lg={12}>
                          <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col lg={12} className="text-end pull-right">
                      <Form.Group className="me-2" controlId="formBasicPassword"></Form.Group>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0">
                  <div>
                    {expenseList.length > 0 ? (
                      <Row>
                        <Col lg={12} xs={12}>
                          <div className="mb-4">
                            <DataTable
                              columns={columns}
                              data={expenseList}
                              customStyles={customStyles}
                              theme="solarized"
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <div className="text-center">
                        <img src="images/nodatafound.svg" alt="No Data" />
                        <h5 className="mb-1">Journal Report</h5>
                        <div>This report doesn’t have any data</div>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </div>
        </Container>
      </section>

      {/* Modal for viewing journal entry details */}
      <Modal
        show={isModelOpen}
        onHide={() => setIsModelOpen(false)}
        size="lg" // Adjust the size to 'lg' or 'xl' based on your preference
        centered // Centers the modal in the viewport
      >
        <Modal.Header closeButton>
          <Modal.Title>Journal Entry Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
          {selectedEntry ? (
            <div>
              <p><strong>Entry Number:</strong> {selectedEntry.entryNo}</p>
              <p><strong>Date:</strong> {new Date(selectedEntry.date).toLocaleDateString()}</p>
              {/* Render journalEntryArr in tabular format */}
              {renderJournalEntryDetails()}
            </div>
          ) : (
            <p>No entry selected.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModelOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VehicleJournalEntry;
