import React, { useEffect, useState, useRef } from "react";
import config from "../../config";
import { Formik } from "formik";
import "../generalCSS/userlogin.css";
import {
  ForgetPasswordSchema,
  ForgetPasswordOTPSchema,
  ResetPasswordOTPSchema,
} from "../../validations/signup";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  ForgotPasswordAction,
  getCountryCodeAction,
  verifyForgetPasswordOTPAction,
  ResetPasswordAction,
} from "../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

const Forgetpassword = () => {
  const navigate = useNavigate();
  const [isStep, setIsStep] = useState(1);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [countryCode, setcountryCode] = useState({
    label: "+592",
    value: "592",
  });
  const [countryoptions, setcountryoptions] = useState([]);
  // Use useRef to create a reference for each input field
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  useEffect(() => {
    getCountryCodeAPI();
  }, []);

  const getCountryCodeAPI = async () => {
    let res = await getCountryCodeAction();
    let arry = [];
    if (res.success) {
      for (let x in res.data) {
        let obj = {};
        obj.label = `+${res.data[x].phonecode}`;
        obj.value = res.data[x].phonecode;
        arry.push(obj);
      }
      setcountryoptions(arry);
    }
  };

  const inputRefs = useRef([]);

  const handleInputOtp = (e, index) => {
    const { value } = e.target;

    // Move to the next field if a number is entered
    if (value && index < 4) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  // Function to move focus to the next input field
  const handleInput = (e, nextRef) => {
    // Move to the next input only if a digit is entered
    if (e.target.value.length === 1 && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const SubmitForm = async (formData) => {
    setisLoading(true);
    formData.countryCode = countryCode.value;
    setMobileNumber(formData.emailormobile);
    let res = await ForgotPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setOtp(res.data);
      // toast.success(res.data);
      setIsStep(2);
      setTimeout(() => {
        input1Ref.current.focus();
      }, 200);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const resendOTP = async () => {
    let formData = { emailormobile: mobileNumber, countryCode: countryCode.value};
    let res = await ForgotPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
    } else {
      toast.error(res.msg);
    }
  };

  const verifyOTPForm = async (values) => {
    let enteredOTP =
      values.otp1 + values.otp2 + values.otp3 + values.otp4 + values.otp5;
    let formData = {
      mobileNumber: mobileNumber,
      otp: enteredOTP,
      countryCode: countryCode.value,
    };
    let res = await verifyForgetPasswordOTPAction(formData);
    if (res.success) {
      setIsStep(3);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const resetPasswordForm = async (formData) => {
    formData.emailormobile = mobileNumber;
    let res = await ResetPasswordAction(formData);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        navigate(`${config.baseurl}userlogin`);
      }, 2000);
    } else {
      toast.error(res.msg);
    }
  };

  const SelectStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#cf5416" : "#fff",
      minWidth: "63px",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      height: "20px",
      minHeight: "30px",
      borderColor: "#E9E9E9",
      minWidth: "63px",
      borderRadius: "0",
      backgroundColor: "#E9E9E9",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      height: "30px",
      margin: "0px",
    }),
  };

  return (
    <>
      <Toaster />
      <section className="login-section pt-0 pb-0 overflow-hidden vh-80">
        {isStep == 1 ? (
          <Container fluid>
            <Row className="">
              <Col xl={7} lg={7} className="p-0">
                <img
                  src="assets/images/background/login2.jpg"
                  className="userbg"
                />
              </Col>
              <Col xl={5} lg={5} className="px-3 pt-5">
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>FORGOT PASSWORD</b>
                        </h3>
                        <p>Enter your registered mobile number</p>
                      </div>
                    </div>

                    <Formik
                      initialValues={{
                        emailormobile: "",
                        countryCode: countryCode.value,
                      }}
                      validationSchema={ForgetPasswordSchema}
                      onSubmit={(values) => {
                        SubmitForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <Row>
                              <Col xl={12} lg={12}>
                                <div className="mb-3 ">
                                  <InputGroup className="reactSelect position-relative" onKeyPress={(event) => {
                                    if (
                                      !/^\d*[]?\d{0,1}$/.test(event.key)
                                    ) {
                                      event.preventDefault();
                                    }
                                  }}>
                                    {/* <Form.Select aria-label="Default select example" name="countryCode" value={values.countryCode} onChange={handleChange}>
                                                                            {countryList.map(item => (
                                                                                <option value={item.phonecode}>+{item.phonecode}</option>
                                                                            ))}
                                                                        </Form.Select> */}
                                    <Select
                                      aria-label="countryCode"
                                      name="countryCode"
                                      onBlur={handleBlur}
                                      options={countryoptions}
                                      value={countryCode}
                                      onChange={setcountryCode}
                                      labelledBy="Country Code"
                                      styles={SelectStyles}
                                    />
                                    <Form.Control
                                      placeholder="Enter phone number"
                                      aria-label="emailormobile"
                                      name="emailormobile"
                                      onKeyPress={(event) => {
                                        if (
                                          !/^\d*[]?\d{0,1}$/.test(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.emailormobile}
                                    />
                                  </InputGroup>
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.emailormobile &&
                                      touched.emailormobile && (
                                        <div className="text-danger">
                                          {errors.emailormobile}
                                        </div>
                                      )}
                                  </Form.Text>
                                </div>
                              </Col>

                              <Col xl={12} lg={12} className="mt-2">
                                {isLoading ? (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="rounded-5 px-4 btn btn-primary w-100"
                                  >
                                    Processing
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="rounded-5 px-4 btn btn-primary w-100"
                                  >
                                    Forgot Password
                                  </Button>
                                )}
                              </Col>
                              <Col xl={12} lg={12} className="mt-2">
                                <div className="text-center mt-2">
                                  <div>
                                    <Link
                                      to={`${config.baseurl}userlogin`}
                                      className="text-decoration-none text-black"
                                    >
                                      <u>Back To Login</u>
                                    </Link>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : isStep == 2 ? (
          <Container fluid>
            <Row className="">
              <Col xl={7} lg={7} className="p-0">
                <img
                  src="assets/images/background/login2.jpg"
                  className="userbg"
                />
              </Col>
              <Col xl={5} lg={5} className="px-3 pt-5">
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>FORGET PASSWORD</b>
                        </h3>
                        <p>
                          “If your Mobile is registered with us, you will receive an
                          email/SMS to reset your password.”
                        </p>
                      </div>
                    </div>

                    <Formik
                      initialValues={{ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "" }}
                      validationSchema={ForgetPasswordOTPSchema}
                      onSubmit={(values) => verifyOTPForm(values)}
                    >
                      {({ handleChange, handleSubmit }) => (
                        <Form onSubmit={handleSubmit}>
                          <Row>
                            <Col xl={12} lg={12}>
                              <Form.Group className="mb-4 d-flex m-verification">
                                {[...Array(5)].map((_, index) => (
                                  <Form.Control
                                    key={index}
                                    type="text"
                                    autoComplete="off"
                                    name={`otp${index + 1}`}
                                    className="me-3 text-center"
                                    maxLength="1"
                                    pattern="[0-9]"
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    onChange={(e) => {
                                      handleChange(e);
                                      handleInputOtp(e, index);
                                    }}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onKeyPress={(event) => {
                                      if (!/^\d$/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                ))}
                              </Form.Group>
                            </Col>

                            <Col xl={12} lg={12}>
                              <div className="text-center mt-4 pb-5">
                                <span
                                  onClick={resendOTP}
                                  style={{ cursor: "pointer" }}
                                  className="text-decoration-none"
                                >
                                  Didn’t receive code?{" "}
                                  <span style={{ color: "#E46422" }}>Send Again</span>
                                </span>
                              </div>
                            </Col>

                            <Col xl={12} lg={12}>
                              <Button variant="primary" type="submit" className="w-100">
                                VERIFY OTP
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <Container fluid>
            <Row className="">
              <Col xl={7} lg={7} className="p-0">
                <img
                  src="assets/images/background/login2.jpg"
                  className="userbg"
                />
              </Col>
              <Col xl={5} lg={5} className="px-3 pt-5">
                <div className="login-container">
                  <div className="login-form">
                    <div className="login-logo">
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                      <div className="pt-4 pb-4">
                        <h3 className="mb-3">
                          <b>RESET PASSWORD</b>
                        </h3>
                        <p>Enter new password</p>
                      </div>
                    </div>
                    <Formik
                      initialValues={{
                        password: "",
                        confirmPassword: "",
                      }}
                      validationSchema={ResetPasswordOTPSchema}
                      onSubmit={(values) => {
                        resetPasswordForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    aria-label="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password ? values.password : ''}
                                  />
                                  {errors.password && touched.password && (
                                  <div className="text-danger">
                                    {errors.password}
                                  </div>
                                )}
                                </Form.Group>
                                
                              </Col>

                              <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="Confirm Password"
                                    aria-label="password"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                  />
                                   {errors.confirmPassword &&
                                  touched.confirmPassword && (
                                    <div className="text-danger">
                                      {errors.confirmPassword}
                                    </div>
                                  )}
                                </Form.Group>
                               
                              </Col>

                              <Col xl={12} lg={12}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100"
                                >
                                  RESET PASSWORD
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </>
  );
};

export default Forgetpassword;
