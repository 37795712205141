import React, { useState, useEffect, useContext } from "react";
import Header from "../../generalPages/directives/header";
import Footer from "../commanComponents/userfooter";
import config from "../../coreFIles/config";
import Maps from "../Maps";
import slugify from "slugify";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Dropdown,
  InputGroup,
  Modal,
  Form,
} from "react-bootstrap";
import "../commanComponents/componentCSS/propertydetail.css";
import "../commanComponents/componentCSS/vehicledetail.css";
import {
  getVehicleDetailActionbyid,
  addWishlistAction,
  removeFromWishlistAction,
  checkvehicleBookingDetailsAction,
  getVehicleAvailableDataAction,
  getWishListAction,
} from "../../Action/user.action";
import { insertSupportRequestAction } from "../../Action/propertyOwner.action";
import { toast, Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineHeart, AiFillHeart, AiFillStar } from "react-icons/ai";
import { RxCopy } from "react-icons/rx";
import { Formik } from "formik";
import { AddTicketSchema } from "../../validations/ticket";
import { BsShare, BsWhatsapp } from "react-icons/bs";
import "../commanComponents/componentCSS/listingproperty.css";
import { useNavigate } from "react-router-dom";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { FaMusic, FaBluetoothB } from "react-icons/fa";
import {
  MdElectricCar,
  MdGpsFixed,
  MdOutlineKeyboardVoice,
} from "react-icons/md";
import { GiSteeringWheel } from "react-icons/gi";
import { LuDog } from "react-icons/lu";
import { IoSunnyOutline } from "react-icons/io5";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { FaStar } from "react-icons/fa6";
import Cookies from "js-cookie";
import {
  LinkedinShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillWechat,
} from "react-icons/ai";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../commanComponents/componentCSS/swiper.css";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { CurrencyContext } from "../../currencyConvertor/CurrencyContext";


const loginData = !Cookies.get("loginSuccessPropertyOwner")
  ? []
  : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const VehicleDetails = () => {
  const { currency, convertPrice } = useContext(CurrencyContext);
  const [show, setShow] = useState(false);
  const [isViewOpen, setisViewOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseHost = () => setisHostRequestOpen(false);
  const handleOpenHost = () => setisHostRequestOpen(true);
  const handleCloseview = () => setisViewOpen(false);
  const handleOpenview = () => setisViewOpen(true);

  const url = window.location.href;
  const urlParams = new URLSearchParams(new URL(url).search);
  const id = urlParams.get("id");
  const checkin = urlParams.get("checkin");
  const checkout = urlParams.get("checkout");
  const propertyPurchaseTypeId = urlParams.get("propertyPurchaseTypeId");
  const gydPrice = Cookies.get("GYDPrice") || "Not Available";


  const {
    checkintime,
    checkouttime,
  } = useParams();
  const navigate = useNavigate();

  const [vehicleDetails, setvehicleDetails] = useState({
    vehicleName: "",
    engineArr: [],
    vehicleFeatures: [],
    bikeParking: "",
    carParking: "",
    categoryName: "",
    checkIn: "",
    checkOut: "",
    datetime: "",
    description: "",
    email: "",
    id: "",
    imageArr: [],
    isDeleted: "",
    location: "",
    price: "",
    vehicleName: "",
    propertyPurchaseType: "",
    propertyPurchaseTypeId: "",
    status: "",
    terms: "",
    latitude: "",
    longitude: "",
  });
  const [imagesArr, setimagesArr] = useState([]);
  const [engineArr, setengineArr] = useState([]);
  const [vehicleFeaturesArr, setvehicleFeaturesArr] = useState([]);
  const [passengerCount, setpassengerCount] = useState({
    passenger: 0,
  });
  const [isHostRequestOpen, setisHostRequestOpen] = useState(false);
  const [wishList, setwishList] = useState([]);
  const [values, setValues] = useState([
    new DateObject(),
    new DateObject().add(1, "day"),
  ]);

  const [bookingDates, setBookingDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [checkIntime, setcheckIntime] = useState();
  const [checkOutTime, setcheckOutTime] = useState();
  const [totalBookingHour, settotalBookingHour] = useState(0);
  const [availableVehicle, setAvailableVehicle] = useState(0);

  useEffect(() => {
    getListingDetails();
    getwishList();
    localStorage.clear("PropertDetail");
  }, []);

  const getListingDetails = async () => {
    let res = await getVehicleDetailActionbyid({ id: id, checkInDateTime: `${checkin}`, checkOutDateTime: `${checkout}` });
    console.log(res.data, "vehcileOnwerNAme");
    if (res.success) {
      setvehicleDetails(res.data);
      setimagesArr(JSON.parse(res.data.imageArr));
      console.log(JSON.parse(res.data.imageArr));
      setvehicleFeaturesArr(res.data.vehicleFeatures);
      setengineArr(res.data.engineArr);
    }
    if (checkin && checkout) {
      setBookingDates((old) => {
        return { ...old, ["startDate"]: checkin, ["endDate"]: checkout };
      });

      setValues([new DateObject(checkin), new DateObject(checkout)]);
      //Difference in number of days
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);
      setcheckIntime(checkintime);
      setcheckOutTime(checkouttime);

      // const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
      //   (dateFinal - dateInitial) / (1000 * 3600);
      // const hour = getHoursDiffBetweenDates(date1, date2);
      // settotalBookingHour(hour);
    }
  };

  console.log("imagesArr", imagesArr);


  const getwishList = async () => {
    if (loginData?.id) {
      let res = await getWishListAction({});
      if (res.success) {
        setwishList(res.data.vehicleList);
      } else {
        setwishList([]);
      }
    }
  };

  const createMarkup = () => {
    return { __html: vehicleDetails.description };
  };

  const createMarkupTerms = () => {
    return { __html: vehicleDetails.terms };
  };

  const cancellateionDes = () => {
    return { __html: vehicleDetails.cancellationAdditionalRules };
  };

  useEffect(() => {
    if (values[0].format()) {
      setBookingDates((old) => {
        return { ...old, ["startDate"]: values[0].format() };
      });
    }

    if (values.length > 1) {
      setBookingDates((old) => {
        return { ...old, ["endDate"]: values[1].format() };
      });

      const start = new Date(String(values[0].format()));
      const end = new Date(String(values[1].format()));

      const getHoursDiffBetweenDates = (dateInitial, dateFinal) =>
        (dateFinal - dateInitial) / (1000 * 3600);
      const hour = getHoursDiffBetweenDates(start, end);

      let inDays = Math.ceil(hour / 24);

      settotalBookingHour(inDays);
      getVehicleAvailableData(moment(values[0].format()).format("YYYY-MM-DD"), moment(values[1].format()).format("YYYY-MM-DD"), moment(values[0].format()).format("HH:mm:ss"), moment(values[1].format()).format("HH:mm:ss"))
    }
  }, [values]);

  const getVehicleAvailableData = async (check_in_date, check_out_date, pickUp, dropOff) => {
    let res = await getVehicleAvailableDataAction(
      {
        vehicleId: id,
        checkInDate: check_in_date,
        checkOutDate: check_out_date,
        checkInTime: pickUp,
        checkOutTime: dropOff
      });
    if (res.success) {
      setAvailableVehicle(res.data[0].availableVehicle)
    }
  }

  const bookHandler = async (e, name) => {
    if (loginData.length == 0) {
      localStorage.setItem("PropertDetail", `${config.baseUrl}vehicledetail/${slugify(name, { lower: true, strict: true })}?id=${id}&checkin${moment(bookingDates.startDate).format("YYYY-MM-DD HH:mm")}&checkout=${moment(bookingDates.endDate).format("YYYY-MM-DD HH:mm")}`);
      window.location.href = `${config.baseUrl}userlogin`;
    } else {
      if (parseInt(totalBookingHour) > 0) {
        if (parseInt(availableVehicle) == 0) {

          // if (parseInt(vehicleDetails.minimumTripDuration) > parseInt(totalBookingHour)) {
          //   toast.error(`Minimum trip duration is ${vehicleDetails.minimumTripDuration} Days`);
          //   return
          // }

          // if (parseInt(vehicleDetails.maximumTripDuration) < parseInt(totalBookingHour)) {
          //   toast.error(`Maximum trip duration is ${vehicleDetails.maximumTripDuration} Days`);
          //   return
          // }

          navigate(`${config.baseUrl}vehiclepayment/${slugify(name, { lower: true, strict: true })}?id=${id}&checkin=${moment(bookingDates.startDate).format("YYYY-MM-DD HH:mm:ss")}&checkout=${moment(bookingDates.endDate).format("YYYY-MM-DD HH:mm:ss")}&passenger=${passengerCount.passenger}`
          );
        } else {
          toast.error('Sorry, the vehicle is not available on the specified date and time as it has been already booked.');
        }
      } else {
        toast.error('Please Add The Timing');
      }

    }
  };

  const addToWishList = async (e, id, type, status) => {
    if (loginData?.id) {
      let res =
        status == 1
          ? await addWishlistAction({ id: id, type: type })
          : await removeFromWishlistAction({ id: id, type: type });
      if (res.success) {
        toast.success(res.msg);
        getListingDetails();
        getwishList();
      } else {
        toast.error(res.msg);
      }
    } else {
      toast.error(
        "Please login first  You can add vehicles to your wishlist after logging in."
      );
      getwishList();
    }
  };
  const formSubmit = async (data) => {
    data.propertyId = id;
    data.type = 2;
    data.ticketType = 2;
    data.touserId = vehicleDetails?.ownerId;
    if (loginData?.id) {
      let res = await insertSupportRequestAction(data);
      if (res.success) {
        toast.success(res.msg);
        setisHostRequestOpen(false);
      } else {
        toast.error(res.msg);
      }
    } else {
      toast.error("You must be logged in first!");
    }
  };
  const copyText = () => {
    toast.success("Copied!");
    navigator.clipboard.writeText(window.location);
  };

  const selectedLocation = {
    lat: vehicleDetails.latitude,
    lng: vehicleDetails.longitude,
    location: vehicleDetails.location,
  };

  return (
    <>
      <Header />
      <section className="vehicleDetails  pt-0 pb-0 overflow-hidden">
        <Container className="mt-5">
          <Toaster />
          <Row className="align-items-center">
            <Col lg={6}>
              <h3>
                <b>{vehicleDetails.vehicleName}</b>
              </h3>
              <p className="mb-0">{vehicleDetails.location}</p>
            </Col>
            <Col lg={6} className="text-right">
              <div className="socialicon">
                <span className="me-3">
                  <AiFillStar fill="#E46422" width="20px" /> &nbsp;
                  {vehicleDetails.ratingArr == null
                    ? "0"
                    : parseFloat(
                      JSON.parse(vehicleDetails.ratingArr)
                        .map((items) => items.ratings)
                        .reduce((prev, next) => prev + next) /
                      JSON.parse(vehicleDetails.ratingArr).length
                    ).toFixed(1)}
                  {/* 4.8 */}
                </span>
                <span className="me-3">

                  {wishList.length > 0 &&
                    wishList.find(
                      (wishitem) =>
                        wishitem.vehicleId == vehicleDetails.id &&
                        wishitem.status == 1 &&
                        wishitem.userId == loginData?.id
                    ) ? (
                    <AiFillHeart
                      width="20px"
                      fill="#D8000C"
                      onClick={(e) => addToWishList(e, vehicleDetails.id, 2, 0)}
                    />
                  ) : (
                    <AiOutlineHeart
                      width="20px"
                      fill="#D8000C"
                      AiOutlineHeart
                      onClick={(e) => addToWishList(e, vehicleDetails.id, 2, 1)}
                    />
                  )}
                </span>
                <span>
                  <Button
                    variant="transparent"
                    className="border-0 "
                    onClick={handleShow}
                  >
                    {" "}
                    <BsShare />
                  </Button>
                  <Modal show={show} onHide={handleClose} size="md">
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <b>Share this vehicle detail</b>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-flex align-items-center">
                        <div className="me-2">
                          {imagesArr.length > 0 ? (
                            <img
                              // src="assets/images/property2.svg"
                              src={imagesArr[0]?.fileNameImage}
                              height={`62px`}
                              width={`100px`}
                              className="object-fit-contain rounded-2"
                            />
                          ) : (
                            <img
                              src="assets/images/property2.svg"
                              height={`62px`}
                              width={`100px`}
                              className="object-fit-contain rounded-2"
                            />
                          )}
                        </div>
                        <div>
                          <p className="mb-0">
                            vehicle in ${vehicleDetails.perHourRate} · per hour
                            rate
                          </p>
                        </div>
                      </div>
                      <div>
                        <br />
                        <Row>
                          <Col lg={6} className="mb-3">
                            <Button
                              variant="transparent"
                              className="rounded-3 w-100 border-1 border-secondary"
                              onClick={(e) => copyText(e)}
                            >
                              <RxCopy size={23} fill="#E46422" />
                              &nbsp; Copy Link
                            </Button>
                          </Col>
                          <Col lg={6} className="mb-3">
                            <Button
                              variant="transparent"
                              className="rounded-3 w-100 border-1 border-secondary"
                              onClick={(e) => copyText(e)}
                            >
                              <LinkedinShareButton
                                url={url}
                                title=""
                              >
                                <AiFillLinkedin fill="#0072b1" size={23} />
                                &nbsp; Linkedin
                              </LinkedinShareButton>
                            </Button>
                          </Col>

                          <Col lg={6} className="mb-3">
                            <Button
                              variant="transparent"
                              className="rounded-3 w-100 border-1 border-secondary"
                            >
                              <WhatsappShareButton
                                url={url}
                                title=""
                              >
                                <BsWhatsapp fill="#25d366" size={23} />
                                &nbsp; Whatsapp
                              </WhatsappShareButton>
                            </Button>
                          </Col>
                          <Col lg={6} className="mb-3">
                            <Button
                              variant="transparent"
                              className="rounded-3 w-100 border-1 border-secondary"
                              onClick={(e) => copyText(e)}
                            >
                              <FacebookShareButton
                                url={url}
                                title=""
                              >
                                <AiFillFacebook fill="#1877F2" size={23} />{" "}
                                Facebook
                              </FacebookShareButton>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <div className="d-none">
                    <WhatsappShareButton
                      url={url}
                      title=" "
                    >
                      <AiFillWechat style={{ opacity: "0.5" }} size={18} />
                    </WhatsappShareButton>
                    <FacebookShareButton
                      url={url}
                      title=" "
                    >
                      <AiFillFacebook style={{ opacity: "0.5" }} size={18} />
                    </FacebookShareButton>
                  </div>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3 px-0">
          <Row>
            <Col lg={6} className="px-1 mb-2">
              <div className="vehicleDetailsImg">
                {imagesArr.length > 0 ? (
                  <img
                    src={`${imagesArr[0].fileNameImage}`}
                  />
                ) : (
                  <img src={"assets/images/no-image.avif"} />
                )}
              </div>
            </Col>
            <Col lg={6} className="text-right px-1 mb-4">
              <div className="collapsImg">
                <div className="nft_pic nft_multi">
                  <a className="">
                    {imagesArr.length > 1 ? (
                      <img
                        className='inner_vehical_img'

                        src={`${imagesArr[1].fileNameImage}`}
                      />
                    ) : (
                      <img
                        className='inner_vehical_img'
                        src={"assets/images/no-image.avif"} />
                    )}
                  </a>
                  <a className="">
                    {imagesArr.length > 2 ? (
                      <img
                        className='inner_vehical_img'

                        src={`${imagesArr[2].fileNameImage}`}
                      />
                    ) : (
                      <img
                        className='inner_vehical_img'
                        src={"assets/images/no-image.avif"} />
                    )}
                  </a>
                  <a className="">
                    {imagesArr.length > 3 ? (
                      <img
                        className='inner_vehical_img'

                        src={`${imagesArr[3].fileNameImage}`}
                      />
                    ) : (
                      <img
                        className='inner_vehical_img'
                        src={"assets/images/no-image.avif"} />
                    )}
                  </a>
                  <a className="">
                    {imagesArr.length > 4 ? (
                      <img
                        className='inner_vehical_img'

                        src={`${imagesArr[4].fileNameImage}`}
                      />
                    ) : (
                      <img
                        className='inner_vehical_img'
                        src={"assets/images/no-image.avif"} />
                    )}
                    {imagesArr.length > 1 ? (
                      <Button
                        variant="default"
                        className="viewmore position-absolute rounded-2 btn-sm h-auto text-white border"
                        onClick={handleOpenview}
                      >
                        View More
                      </Button>
                    ) : (
                      ""
                    )}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className=" mb-4">
          <Container>
            <Row>
              <Col lg={8}>
                <div className="amenities_list mb-5">
                  <Row>
                    <Col lg={4} md={4} xs={6}>
                      <div className="text-center border-end">

                        <img src={"assets/images/petrol.svg"} />
                        {engineArr.length > 0 ? (
                          <p className="mt-2">{engineArr[0].name}</p>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg={4} md={4} xs={6}>
                      <div className="text-center border-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="35"
                          viewBox="0 0 29 35"
                          fill="none"
                        >
                          <path
                            d="M26.5833 27.0456H25.7358L24.972 12.7274C24.9691 10.0926 22.807 7.95758 20.1387 7.95466H19.3333V6.36356H20.9443C21.8338 6.36268 22.5545 5.65102 22.5554 4.77274V3.18192C22.5533 1.42536 21.1119 0.00203914 19.3333 0H9.66667C7.8878 0.00203914 6.44641 1.42536 6.44435 3.18192V4.77274C6.44523 5.65102 7.16592 6.36268 8.05536 6.36356H9.66667V7.95466H8.86101C6.20746 7.95204 4.04921 10.0652 4.02915 12.6855L3.26392 27.0456H2.41667C1.08237 27.0471 0.001475 28.1144 0 29.432V30.2273C0.00294999 32.8621 2.16503 34.9971 4.83333 35H24.1667C26.8347 34.9971 28.9971 32.8621 29 30.2273V29.432C28.9985 28.1144 27.9173 27.0471 26.5833 27.0456ZM8.05536 3.18192C8.05624 2.30364 8.77723 1.59169 9.66667 1.59082H19.3333C20.2228 1.59169 20.9435 2.30364 20.9443 3.18192V4.77274H8.05536V3.18192ZM11.2777 6.36356H17.722V7.95466H11.2777V6.36356ZM5.63869 12.7274C5.64076 10.9708 7.08214 9.54752 8.86101 9.54548H20.1387C20.9998 9.54898 21.8238 9.89126 22.4288 10.496C23.0339 11.101 23.3699 11.919 23.3625 12.7694L24.1236 27.0456H22.107L21.355 14.3182C21.3529 12.5617 19.9115 11.1383 18.1327 11.1363H10.867C9.10439 11.1325 7.66773 12.5314 7.64648 14.2716L6.89275 27.0456H4.8764L5.63869 12.7274ZM20.4936 27.0456H8.50612L9.25602 14.3182C9.25691 13.4399 9.9776 12.7283 10.867 12.7274H18.133C18.5675 12.7306 18.9826 12.9057 19.2858 13.2133C19.5888 13.5212 19.7543 13.9357 19.7457 14.3648L20.4936 27.0456ZM27.3887 30.2273C27.3866 31.9838 25.9452 33.4069 24.1667 33.4092H4.83333C3.05446 33.4069 1.61337 31.9838 1.61101 30.2273V29.432C1.6119 28.993 1.9721 28.6373 2.41667 28.6364H26.5833C27.0279 28.6373 27.3878 28.993 27.3887 29.432V30.2273Z"
                            fill="#E46422"
                          />
                        </svg>
                        <p className="mt-2">{vehicleDetails.seats} Seats</p>
                      </div>
                    </Col>


                  </Row>
                </div>


                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Pickup Location</b>
                    </h5>
                    <p className="text-grey">
                      {vehicleDetails.location}
                    </p>
                    <p className="text-grey">
                      Trip Duration: {vehicleDetails.minimumTripDuration} Days - {vehicleDetails.maximumTripDuration} Days
                    </p>
                  </div>
                </Col>
                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Vehicle Description</b>
                    </h5>
                    <p className="text-grey">
                      <div dangerouslySetInnerHTML={createMarkup()} />
                    </p>
                  </div>
                </Col>

                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Terms</b>
                    </h5>
                    <Row>
                      <Col lg={12} className="mb-2">
                        <p className="text-grey">
                          <div dangerouslySetInnerHTML={createMarkupTerms()} />
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Booking Cancellation Policies</b>
                    </h5>
                    <Row>
                      <Col lg={12} className="mb-2">
                        <p className="text-grey">
                          <div dangerouslySetInnerHTML={cancellateionDes()} />
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Offered Features</b>
                    </h5>
                    <Row>
                      {vehicleFeaturesArr.length > 0
                        ? vehicleFeaturesArr.map((data) => (
                          <Col lg={4}>
                            <div className="">
                              <p>
                                {data.name == "Pet Friendly" ? (
                                  <LuDog />
                                ) : data.name == "GPS" ? (
                                  <MdGpsFixed />
                                ) : data.name == "Power Steering" ? (
                                  <GiSteeringWheel />
                                ) : data.name == "Bluetooth" ? (
                                  <FaBluetoothB />
                                ) : data.name == "Sunroof" ? (
                                  <IoSunnyOutline />
                                ) : data.name == "Voice Control" ? (
                                  <MdOutlineKeyboardVoice />
                                ) : data.name == "Voice" ? (
                                  <MdOutlineKeyboardVoice />
                                ) : (
                                  ""
                                )}
                                &nbsp;{data.name}
                              </p>
                            </div>
                          </Col>
                        ))
                        : ""}
                    </Row>

                  </div>
                </Col>
                <Col lg={12} className="mb-5">
                  <div>
                    <h5 className="mb-3">
                      <b>Engine Types</b>
                    </h5>
                    <Row>
                      {engineArr.length > 0
                        ? engineArr.map((data) => (
                          <Col lg={4}>
                            <div className="">
                              <p>
                                {data.name == "Electric" ? (
                                  <MdElectricCar />
                                ) : data.name == "GPS" ? (
                                  <MdGpsFixed />
                                ) : data.name == "Diesel" ||
                                  data.name == "Petrol" ||
                                  data.name == "CNG" ? (
                                  <BsFillFuelPumpDieselFill />
                                ) : data.name == "Bluetooth" ? (
                                  <FaBluetoothB />
                                ) : data.name == "Sunroof" ? (
                                  <IoSunnyOutline />
                                ) : data.name == "Music" ? (
                                  <FaMusic />
                                ) : data.name == "Voice" ? (
                                  <MdOutlineKeyboardVoice />
                                ) : (
                                  ""
                                )}
                                &nbsp;{data.name}
                              </p>
                            </div>
                          </Col>
                        ))
                        : ""}
                    </Row>

                  </div>
                </Col>
                {!vehicleDetails.video ||
                  vehicleDetails.video == null ||
                  vehicleDetails.video == undefined ? (
                  ""
                ) : (
                  <Col lg={12} className="mb-3">
                    <div>
                      <h5 className="mb-3">
                        <b>Video</b>
                      </h5>
                      <Row>
                        <Col lg="6">

                          <>
                            <video width="300" controls>
                              <source
                                src={config.imageUrl + vehicleDetails.video}
                              />
                            </video>
                            <br />
                            <br />
                            <a
                              href={config.imageUrl + vehicleDetails.video}
                              target="_blank"
                            >
                              <Button
                                variant="primary"
                                className="rounded-2 h-auto mb-3"
                              >
                                View Video
                              </Button>

                            </a>
                          </>

                        </Col>
                        <Col lg="6" style={{ display: "none" }}>
                          {!vehicleDetails.document ||
                            vehicleDetails.document == null ||
                            vehicleDetails.document == undefined ? (
                            <p>No data found!!</p>
                          ) : (
                            <>
                              <iframe
                                src={config.imageUrl + vehicleDetails.document}
                                frameborder="0"
                              ></iframe>
                              <br />
                              <br />
                              <a
                                href={config.imageUrl + vehicleDetails.document}
                                target="_blank"
                              >
                                <Button
                                  variant="primary"
                                  className="rounded-2 h-auto mb-3"
                                >
                                  View Document
                                </Button>
                              </a>
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )}
              </Col>
              <Col lg={4} className="mb-4">
                <div>
                  {/* ---------- */}
                  <Card className="rounded-4">
                    <div className="d-flex justify-content-between align-items-center border-bottom border-grey p-2 mb-4">
                      <h6 className="mb-0">
                        <b>${convertPrice(vehicleDetails.perHourRate)} {currency}/Day</b>
                      </h6>
                      <Button
                        variant="primary"
                        className="rounded-5"
                        onClick={handleOpenHost}
                      // style={{ cursor: "pointer", marginTop: "-10px" }}
                      >
                        Contact Host
                      </Button>
                    </div>
                    <div className="px-4 pb-4 two_month_date">
                      <Row className="align-items-center">
                        <Col lg={12}>

                          <Button
                            variant=""
                            className="w-100 mb-4 text-black border-dark rounded-5 position-relative h-auto py-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 20 22"
                              fill="none"
                            >
                              <path
                                d="M14.5 7.16H5.5C5.08579 7.16 4.75 7.49579 4.75 7.91C4.75 8.32421 5.08579 8.66 5.5 8.66H14.5C14.9142 8.66 15.25 8.32421 15.25 7.91C15.25 7.49579 14.9142 7.16 14.5 7.16Z"
                                fill="black"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.89 2.57H15C17.7614 2.57 20 4.80858 20 7.57V16.57C20 19.3314 17.7614 21.57 15 21.57H5C3.67392 21.57 2.40215 21.0432 1.46447 20.1055C0.526784 19.1679 0 17.8961 0 16.57V7.57C0 4.80858 2.23858 2.57 5 2.57H5.09V0.75C5.09 0.335786 5.42579 0 5.84 0C6.25421 0 6.59 0.335786 6.59 0.75V2.57H13.39V0.75C13.39 0.335786 13.7258 0 14.14 0C14.5542 0 14.89 0.335786 14.89 0.75V2.57ZM15 20.07C16.933 20.07 18.5 18.503 18.5 16.57V7.57C18.5 5.637 16.933 4.07 15 4.07H5C3.067 4.07 1.5 5.637 1.5 7.57V16.57C1.5 18.503 3.067 20.07 5 20.07H15Z"
                                fill="black"
                              />
                            </svg>
                            &nbsp;

                            {values.slice(0, 1).map((day, index) => (
                              <>{day.format()}</>
                            ))}
                            -
                            {values.slice(1, 2).map((day, index) => (
                              <>{day.format()}</>
                            ))}

                            <DatePicker
                              value={values}
                              format="MM/DD/YYYY HH:mm"
                              minDate={moment().subtract(1, "day").toDate()}
                              onChange={setValues}
                              numberOfMonths={2}
                              plugins={[
                                <TimePicker position="right" />,
                              ]}
                              range
                            />
                          </Button>
                        </Col>
                        <Col lg={12} className="mb-3">
                          <div className="text-center mt-2 mb-4">
                            <h5></h5>

                          </div>
                          <div className="vlist">
                            <ul className="mb-3 list-unstyled ps-0">
                              <li>
                                <div className="d-flex justify-content-between x-small">
                                  <div className="text-grey">
                                    ${vehicleDetails.perHourRate} x{" "}
                                    {parseInt(totalBookingHour)} Days
                                  </div>
                                  <div className="text-grey">
                                    $
                                    {convertPrice((parseInt(vehicleDetails.perHourRate) * parseInt(totalBookingHour)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currency}
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-between x-small">
                                  <div className="text-grey">Security Deposit</div>
                                  <div className="text-grey">${convertPrice(vehicleDetails.securityDeposit)} {currency}</div>
                                </div>
                              </li>
                              <li>
                                <div className="d-flex justify-content-between x-small">
                                  <div className="text-grey">Booking Cancellation Charges</div>
                                  <div className="text-grey">{vehicleDetails.cancellationPercentage || 0}%</div>
                                </div>
                              </li>
                              {/* <li>
                                <div className="d-flex justify-content-between x-small">
                                  <div className="text-grey">Cleaning and service fee</div>
                                  <div className="text-grey">Included</div>
                                </div>
                              </li> */}
                              {/* <li>
                                <div className="d-flex justify-content-between x-small">
                                  <div className="text-grey">Taxes</div>
                                  <div className="text-grey">Included</div>
                                </div>
                              </li> */}
                              <li>
                                <div className="d-flex justify-content-between x-small text-black">
                                  <div className="fw-bold">  Total <br />{parseInt(totalBookingHour)}-days travel</div>
                                  <div className="fw-bold text-end">
                                    $
                                    {convertPrice((
                                      parseFloat(vehicleDetails.perHourRate) * parseInt(totalBookingHour) +
                                      parseFloat(vehicleDetails.securityDeposit)
                                    ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }))} {currency}
                                    <br />
                                  </div>
                                </div>
                              </li>

                            </ul>
                          </div>
                          <p style={{ color: 'red', fontSize: '13px' }}> Note: Security Deposit Charges is refundable if there is no damages. </p>

                          <Button
                            variant="primary"
                            className="w-100 rounded-5"
                            onClick={(e) => bookHandler(e, vehicleDetails.vehicleName)}
                          >
                            Reserve Now
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="mb-5 mt-4">
                <div>
                  <h5 className="mb-3 d-flex align-items-center ">
                    <b className="me-2">Reviews</b> &nbsp;
                    <span className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M19.9471 7.18026C19.8843 6.99515 19.7686 6.8325 19.6143 6.71238C19.4601 6.59227 19.274 6.51995 19.0791 6.50435L13.3781 6.05141L10.9111 0.591133C10.8325 0.415269 10.7047 0.265898 10.5431 0.161048C10.3815 0.0561979 10.193 0.000350006 10.0004 0.000244291C9.80777 0.000138576 9.61922 0.0557796 9.45751 0.160452C9.2958 0.265125 9.16784 0.414356 9.08908 0.590134L6.62206 6.05141L0.921032 6.50435C0.729486 6.51952 0.546368 6.58955 0.393584 6.70605C0.2408 6.82256 0.12482 6.98061 0.0595198 7.16129C-0.00578041 7.34198 -0.017636 7.53765 0.0253714 7.72489C0.0683787 7.91214 0.164428 8.08303 0.302028 8.21712L4.51505 12.3236L3.02504 18.7747C2.9798 18.97 2.9943 19.1744 3.06666 19.3613C3.13903 19.5482 3.26591 19.7091 3.43085 19.823C3.59579 19.937 3.79117 19.9987 3.99163 20.0002C4.1921 20.0017 4.3884 19.943 4.55505 19.8316L10.0001 16.2021L15.4451 19.8316C15.6155 19.9447 15.8163 20.0029 16.0208 19.9984C16.2252 19.9939 16.4233 19.9269 16.5885 19.8065C16.7537 19.6861 16.8781 19.5179 16.9449 19.3247C17.0117 19.1315 17.0177 18.9225 16.9621 18.7257L15.1331 12.3266L19.6691 8.24512C19.9661 7.97715 20.0751 7.55921 19.9471 7.18026Z"
                          fill="#E46422"
                        />
                      </svg>{" "}
                      &nbsp;
                      <span className="text-primary">
                        {" "}
                        {vehicleDetails.ratingArr == null
                          ? "0"
                          : JSON.parse(vehicleDetails.ratingArr)
                            .map((items) => items.ratings)
                            .reduce((prev, next) => prev + next) /
                          JSON.parse(vehicleDetails.ratingArr).length}
                        {/* 5.0 */}
                      </span>
                    </span>
                  </h5>
                </div>
              </Col>

              <Col lg={12} className="mb-5">
                <div>
                  <Row>
                    {vehicleDetails.ratingArr == null
                      ? ""
                      : JSON.parse(vehicleDetails.ratingArr).map((items) => {
                        return (
                          <Col lg={4} className="mb-3">
                            <div className="d-flex align-items-center mb-2">
                              <img
                                // src="assets/images/testimonial/t1.svg"
                                src={
                                  items.ratingUserProfile == null ||
                                    items.ratingUserProfile == ""
                                    ? "assets/images/profile-img.png"
                                    : `${items.ratingUserProfile}`
                                }
                                className="me-3 reviewImg"
                              />
                              <div>
                                <h6 className="mb-0">
                                  <b>{items.ratingUserName}</b>
                                </h6>
                                <p>
                                  <div className="d-flex align-items-center">
                                    <div className="rating_star">
                                      <FaStar
                                        className="me-1"
                                        fill={
                                          items.ratings >= 1
                                            ? "rgb(228, 100, 34)"
                                            : "#b5b0b0"
                                        }
                                      />
                                      <FaStar
                                        className="me-1"
                                        fill={
                                          items.ratings >= 2
                                            ? "rgb(228, 100, 34)"
                                            : "#b5b0b0"
                                        }
                                      />
                                      <FaStar
                                        className="me-1"
                                        fill={
                                          items.ratings >= 3
                                            ? "rgb(228, 100, 34)"
                                            : "#b5b0b0"
                                        }
                                      />
                                      <FaStar
                                        className="me-1"
                                        fill={
                                          items.ratings >= 4
                                            ? "rgb(228, 100, 34)"
                                            : "#b5b0b0"
                                        }
                                      />{" "}
                                      <FaStar
                                        className="me-1"
                                        fill={
                                          items.ratings >= 5
                                            ? "rgb(228, 100, 34)"
                                            : "#b5b0b0"
                                        }
                                      />
                                    </div>
                                    &nbsp;<b>{items.ratings}</b>
                                  </div>
                                </p>
                                <p className="text-grey mb-0">
                                  {moment(items.createdDate).format("ll")}
                                </p>
                              </div>
                            </div>
                            <p className="text-grey">{items.review}</p>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ width: "100%" }}>
          <Maps selectedLocation={selectedLocation} />
        </div>
        <hr className="mt-0" />
      </section>

      {/* Send Request to host  */}
      <Modal
        show={isHostRequestOpen}
        onHide={() => handleCloseHost(false)}
        dialogClassName="modal-90w modal-right"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Contact Host</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="me-2">
              {!vehicleDetails?.profile ||
                vehicleDetails?.profile == null ||
                vehicleDetails?.profile == undefined ? (
                <img
                  src="assets/images/profile-img.png"
                  height={`62px`}
                  width={`100px`}
                  className="object-fit-contain rounded-2"
                />
              ) : (
                <img
                  src={`${config.imageUrl + vehicleDetails?.profile}`}
                  height={`62px`}
                  width={`100px`}
                  className="object-fit-contain rounded-2"
                />
              )}
            </div>
            <div>
              <p className="mb-0">{vehicleDetails?.firstName}</p>
              <span style={{ fontSize: "12px" }}>
                Typically responds within an hour
              </span>
            </div>
          </div>
          <br />
          <Formik
            initialValues={{
              title: "",
              message: "",
            }}
            validationSchema={AddTicketSchema}
            onSubmit={(values) => {
              formSubmit(values);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Title"
                      onChange={handleChange}
                      name="title"
                      value={values.title}
                    />
                    <span className="text-danger">
                      {errors.title && touched.title ? (
                        <div>{errors.title}</div>
                      ) : null}
                    </span>
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={6}
                      className="h-auto"
                      placeholder="Type your message here"
                      onChange={handleChange}
                      name="message"
                      value={values.message}
                    />
                    <span className="text-danger">
                      {errors.message && touched.message ? (
                        <div>{errors.message}</div>
                      ) : null}
                    </span>
                  </Form.Group>

                  <div className="text-right">
                    <Button
                      variant="primary"
                      className="h-auto rounded-1"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        show={isViewOpen}
        onHide={() => handleCloseview(false)}
        dialogClassName="modal-90w modal-right "
        aria-labelledby="example-custom-modal-styling-title"
        className="hotelImages"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Vehicle Images</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Swiper
              pagination={{
                type: "fraction",
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {imagesArr && imagesArr.length > 0
                ? imagesArr.map((obj) => {
                  return (
                    <SwiperSlide>
                      <img src={`${obj.fileNameImage}`} />
                    </SwiperSlide>
                  );
                })
                : ""}
            </Swiper>
          </>
        </Modal.Body>
      </Modal>

      <Footer />
    </>
  );
};

export default VehicleDetails;
