import React, { useState, useEffect, useMemo, useContext } from "react";
import Header from "../commanComponents/userheader";
import Footer from "../commanComponents/userfooter";
import config from "../../coreFIles/config";
import { toast, Toaster } from "react-hot-toast";
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import {
  getPropertyDetailAction,
  insertBookingDetailsAction,
  getMMGJwt,
  MMGCheckoutPage,
  getGYDPrice
} from "../../Action/user.action";
import { paymentIntentAction } from "../../Action/propertyOwner.action";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
} from "react-bootstrap";
import { useParams, useNavigate, redirect } from "react-router-dom";
import { Buffer } from "buffer";
import moment from "moment";
import { CurrencyContext } from "../../currencyConvertor/CurrencyContext";


const Payment = () => {
  const navigate = useNavigate();
  const { currency, convertPrice } = useContext(CurrencyContext);
  const url = window.location.href;
  const urlParams = new URLSearchParams(new URL(url).search);
  const id = urlParams.get("id");
  const numberOfAdults = urlParams.get("numberOfAdults");
  const checkin = urlParams.get("checkin");
  const checkout = urlParams.get("checkout");
  const numberOfChildren = urlParams.get("numberOfChildren");
  const numberOfInfants = urlParams.get("numberOfInfants");
  const numberOfPets = urlParams.get("numberOfPets");
  const roomType = urlParams.get("roomType");
  const gydPrice = Cookies.get("GYDPrice") || "Not Available";



  const [leaseCount, setleaseCount] = useState({
    years: 0,
    months: 1,
  });
  const [propertyDetail, setpropertyDetail] = useState({
    PropertyName: "",
    amenityArr: [],
    bikeParking: "",
    carParking: "",
    categoryName: "",
    checkIn: "",
    checkOut: "",
    datetime: "",
    description: "",
    documentArr: [],
    email: "",
    guestCount: "",
    id: "",
    imageArr: [],
    isDeleted: "",
    location: "",
    numberOfBathroom: "",
    numberOfBedrooms: "",
    numberOfBeds: "",
    petsAllowed: "",
    price: "",
    propertyName: "",
    propertyPurchaseType: "",
    propertyPurchaseTypeId: "",
    status: "",
    terms: "",
  });
  const [isPaypalVisible, setIsPaypalVisible] = useState(false);
  const [imagesArr, setimagesArr] = useState([]);
  const [amenityArr, setAmenityArr] = useState([]);
  const [MMGToken, setMMGToken] = useState('');
  const [totalBookingDay, settotalBookingDay] = useState(0);
  const [paymentType, setpaymentType] = useState(1); //1=Card ,2=Bank Transfer ,3=Paypal,4=Stripe,5=MMG
  const [clientSecret, setClientSecret] = useState("");
  const [guestCountPerRoom, setGuestCountPerRoom] = useState(0);
  const [chooseplanDetails, setChoosePlanDetails] = useState({
    description: "",
  });
  const [totalAmount, settotalAmount] = useState(0);
  const [orderAmount, setorderAmount] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);

  useEffect(() => {
    getListingDetails();
    localStorage.removeItem("PropertyDetails");
  }, []);

  useEffect(() => {
    if (totalAmount > 0) {
      getMMGPayloadAPI(propertyDetail);
      setIsPaypalVisible(true)
    }
  }, [totalAmount]);


  const getListingDetails = async () => {
    let res = await getPropertyDetailAction({ id: id, roomType: roomType });
    let priceOfRoom = res.data?.price;
    let roomPersonsAllowed = 2
    let totalRoomPrice = 0
    if (res.success) {
      let getPropertyRoomTypeImages = res.data.getPropertyRoomTypeImages;
      setpropertyDetail(res.data);
      let roomPriceRes = res.data.getPropertyRoomPrice;
      if (roomPriceRes.length > 0) {
        setRoomPrice(
          roomPriceRes[0]?.price ? roomPriceRes[0]?.price : res.data?.price
        );
        totalRoomPrice = roomPriceRes[0]?.price ? roomPriceRes[0]?.price : res.data?.price
        priceOfRoom = roomPriceRes[0]?.price
          ? roomPriceRes[0]?.price
          : res.data?.price;
      } else {
        setRoomPrice(res.data?.price);
        totalRoomPrice = res.data?.price;
      }

      setimagesArr(JSON.parse(res.data.imageArr));
      setAmenityArr(res.data.amenityArr);

      if (roomType != 0) {
        const filteredRooms = getPropertyRoomTypeImages.filter(room => room.roomTypeId == roomType);
        if (filteredRooms.length > 0) {
          setGuestCountPerRoom(filteredRooms[0].personsAllowed)
          roomPersonsAllowed = filteredRooms[0].personsAllowed
        } else {
          setGuestCountPerRoom(res.data.guestCount)
          roomPersonsAllowed = res.data.guestCount
        }
      } else {
        setGuestCountPerRoom(res.data.guestCount)
        roomPersonsAllowed = res.data.guestCount
      }

    }
    if (checkin && checkout) {
      //Difference in number of days
      var date1 = new Date(checkin);
      var date2 = new Date(checkout);

      // To calculate the time difference of two dates
      var Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      settotalBookingDay(Difference_In_Days);
      if (res.data?.propertyPurchaseTypeId == 2) {
        const totalGuests = parseInt(numberOfAdults) + parseInt(numberOfChildren);
        const roomsNeeded = Math.ceil(totalGuests / roomPersonsAllowed);
        const baseRoomCost = roomsNeeded * totalRoomPrice * Difference_In_Days;
        let propertyDetails = res.data
        const tax =
          propertyDetails?.taxPercentage
            ? (Math.ceil(totalGuests / 2) * totalRoomPrice * Difference_In_Days * propertyDetails.taxPercentage) / 100
            : 0;

        const cleaningFee =
          propertyDetails?.cleaningAndMaintenanceFee && totalGuests > 0
            ? parseFloat(propertyDetails.cleaningAndMaintenanceFee)
            : 0;

        const petsCost =
          propertyDetails?.petsPrice && numberOfPets > 0
            ? numberOfPets * parseFloat(propertyDetails.petsPrice)
            : 0;

        const securityDeposit = parseFloat(propertyDetails?.securityDeposit || 0);

        const totalAmount =
          baseRoomCost + tax + cleaningFee + petsCost + securityDeposit;

        settotalAmount(parseFloat(totalAmount).toFixed(2))
      } else if (res.data?.propertyPurchaseTypeId == 1) {
        settotalAmount(parseFloat(priceOfRoom));
      } else {

        const cleaningAndMaintenanceFee = parseFloat(
          res?.data?.cleaningAndMaintenanceFee ?? 0
        );
        const securityDeposit = parseFloat(res.data?.securityDeposit ?? 0);
        const price = parseFloat(res.data?.price ?? 0);
        const taxPercentage = parseFloat(res.data?.taxPercentage ?? 0);
        const leasePeriod = parseInt(
          leaseCount[res.data.priceType ? "years" : "months"] ?? 0
        );
        const numberOfAdultsParsed = parseFloat(numberOfAdults ?? 0);

        const baseAmount = price * leasePeriod * numberOfAdultsParsed;
        const taxAmount = (baseAmount * taxPercentage) / 100;

        const totalAmount =
          cleaningAndMaintenanceFee + securityDeposit + baseAmount + taxAmount;
        settotalAmount(totalAmount);
      }

    }
  };

  const getMMGPayloadAPI = async (data) => {
    const encryptionKey = config.fygaroJWTEncryptionKey;

    const numOfAdults = parseInt(numberOfAdults, 10) || 0;
    const roomPriceNum = parseFloat(roomPrice) || 0;
    const taxPercentageNum = parseFloat(data.taxPercentage) || 0;

    const halfAdultsRoundedUp = Math.ceil(numOfAdults / 2);

    var date1 = new Date(checkin);
    var date2 = new Date(checkout);

    var Difference_In_Time = date2.getTime() - date1.getTime();

    var bookingDays = Difference_In_Time / (1000 * 3600 * 24);


    const taxAmountCalculate = (halfAdultsRoundedUp * roomPriceNum * bookingDays * taxPercentageNum) / 100;
    let MMGPayload = {
      id,
      amount: parseFloat(parseFloat(totalAmount) * parseFloat(gydPrice)).toFixed(2),
      //  amount : convertPrice(totalAmount),
      numberOfAdults,
      checkin,
      checkout,
      numberOfChildren,
      numberOfInfants,
      numberOfPets,
      roomType,
      //  numberOfRooms: data.,
      cleaningAndMaintenanceFee: data.cleaningAndMaintenanceFee ? data.cleaningAndMaintenanceFee : 0,
      taxAmount: taxAmountCalculate,
      securityDeposit: data.securityDeposit,
      PetsAmountTotal: data?.petsPrice && numberOfPets > 0
        ? parseInt(numberOfPets) * parseFloat(data?.petsPrice)
        : 0,
      type: 1
    };

    const encryptedPayload = CryptoJS.AES.encrypt(
      JSON.stringify(MMGPayload),
      encryptionKey
    ).toString();


    let jwtReceived = await getMMGJwt({ jwt: encryptedPayload });

    if (jwtReceived.success) {
      setMMGToken(jwtReceived.data);
    }
  };

  const paymentIntent = async () => {
    let amount = totalAmount;

    let res = await paymentIntentAction({ amount: amount });
    if (res.success) {
      setClientSecret({
        clientSecret: Buffer.from(res.client_secret, "base64").toString(
          "ascii"
        ),
      });
    }
  };

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    PAYPAL PAYMENT  START   >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const createOrder = (data, actions) => {
    let amount = totalAmount;
    return actions.order
      .create({
        purchase_units: [
          {
            description: chooseplanDetails.description,
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    let amount = 0;
    let taxAmount = 0;
    let cleaningFee = 0;
    let petAmount = 0;
    if (propertyDetail?.propertyPurchaseTypeId == 2) {
      const totalGuests = parseInt(numberOfAdults) + parseInt(numberOfChildren);
      const roomsNeeded =
        Math.ceil(totalGuests / guestCountPerRoom);
      const baseRoomCost = parseFloat(roomsNeeded) * parseFloat(roomPrice) * parseFloat(totalBookingDay);

      const tax =
        propertyDetail?.taxPercentage
          ? (Math.ceil(totalGuests / 2) * roomPrice * totalBookingDay * propertyDetail.taxPercentage) / 100
          : 0;

      const cleaningFee =
        propertyDetail?.cleaningAndMaintenanceFee && totalGuests > 0
          ? parseFloat(propertyDetail.cleaningAndMaintenanceFee)
          : 0;

      const petsCost =
        propertyDetail?.petsPrice && numberOfPets > 0
          ? numberOfPets * parseFloat(propertyDetail.petsPrice)
          : 0;

      const securityDeposit = parseFloat(propertyDetail?.securityDeposit || 0);

      const totalAmount =
        baseRoomCost + tax + cleaningFee + petsCost + securityDeposit;

      amount = totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
      amount = parseFloat(roomPrice) * parseInt(parseFloat(numberOfAdults));
    } else {
      amount = propertyDetail?.price;
    }



    return actions.order.capture().then(async function (res) {
      if (res.status == "COMPLETED") {
        let arr = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          paymentMethodId: 1,
          numberOfAdults: parseInt(numberOfAdults),
          numberOfChildren: parseInt(numberOfChildren),
          noOfInfants: numberOfInfants,
          noOfPets: numberOfPets,
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          checkInDate: checkin,
          checkOutDate: checkout,
          roomType: roomType,
          securityDeposit: propertyDetail?.securityDeposit,
          cardNumber: "",
          expiryDate: "",
          accountNumber: "",
          amount: amount,
          taxAmount: taxAmount,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          ifscCode: "",
          upiId: "",
          paymentResponse: res,
          PetsAmountTotal: petAmount,
        };
        let arr1 = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          paymentMethodId: 1,
          securityDeposit: propertyDetail?.securityDeposit,
          taxAmount: taxAmount,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          numberOfMonths: parseInt(numberOfAdults),
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          paymentResponse: res,
        };

        let arr3 = {
          propertyId: parseInt(id),
          payerId: res.payer.payer_id,
          orderId: res.id,
          receiptUrl: res.links[0].href,
          taxAmount: taxAmount,
          securityDeposit: 0,
          claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
          paymentMethodId: 1,
          propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
          paymentResponse: res,

        };

        let result = "";
        if (propertyDetail?.propertyPurchaseTypeId == 2) {
          result = await insertBookingDetailsAction(arr);
        } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
          result = await insertBookingDetailsAction(arr1);
        } else {
          result = await insertBookingDetailsAction(arr3);
        }
        if (result.success) {
          toast.success(result.msg);
          setTimeout(() => {
            navigate(`${config.baseUrl}userdashboard/propertyBooking`);
          }, 2000);
        } else {
          toast.error(result.msg);
        }
      } else {
        toast.error(
          "Something went wrong with your payment. Please try again after some time"
        );
      }
    });
  };

  const onError = (data, actions) => {
    toast.error(
      "Something went wrong with your payment. Please try again after some time"
    );
  };

  const disableStripe = () => {
    setClientSecret("");
  };

  useMemo(() => {
    if (paymentType == 2) {
      paymentIntent();
    }
  }, [paymentType]);



  const MMGCheckout = async () => {
    let amount = 0;
    let taxAmount = 0;
    let cleaningFee = 0;
    let petAmount = 0;
    if (propertyDetail?.propertyPurchaseTypeId == 2) {
      const totalGuests = parseInt(numberOfAdults) + parseInt(numberOfChildren);
      const roomsNeeded =
        Math.ceil(totalGuests / guestCountPerRoom);
      const baseRoomCost = parseFloat(roomsNeeded) * parseFloat(roomPrice) * parseFloat(totalBookingDay);

      const tax =
        propertyDetail?.taxPercentage
          ? (Math.ceil(totalGuests / 2) * roomPrice * totalBookingDay * propertyDetail.taxPercentage) / 100
          : 0;

      const cleaningFee =
        propertyDetail?.cleaningAndMaintenanceFee && totalGuests > 0
          ? parseFloat(propertyDetail.cleaningAndMaintenanceFee)
          : 0;

      const petsCost =
        propertyDetail?.petsPrice && numberOfPets > 0
          ? numberOfPets * parseFloat(propertyDetail.petsPrice)
          : 0;

      const securityDeposit = parseFloat(propertyDetail?.securityDeposit || 0);

      const totalAmount =
        baseRoomCost + tax + cleaningFee + petsCost + securityDeposit;

      amount = totalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
      amount = parseFloat(roomPrice) * parseInt(parseFloat(numberOfAdults));
    } else {
      amount = propertyDetail?.price;
    }

    let arr = {
      propertyId: parseInt(id),
      paymentMethodId: 3,
      numberOfAdults: parseInt(numberOfAdults),
      numberOfChildren: parseInt(numberOfChildren),
      receiptUrl: 'xyz',
      noOfInfants: numberOfInfants,
      noOfPets: numberOfPets,
      propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
      checkInDate: checkin,
      checkOutDate: checkout,
      roomType: roomType,
      securityDeposit: propertyDetail?.securityDeposit,
      cardNumber: "",
      expiryDate: "",
      accountNumber: "",
      amount: amount,
      taxAmount: taxAmount,
      claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
      ifscCode: "",
      upiId: "",
      paymentResponse: 'res',
      PetsAmountTotal: petAmount,
    };
    let arr1 = {
      propertyId: parseInt(id),
      paymentMethodId: 3,
      receiptUrl: 'xyz',
      securityDeposit: propertyDetail?.securityDeposit,
      taxAmount: taxAmount,
      claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
      numberOfMonths: parseInt(numberOfAdults),
      propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,
    };

    let arr3 = {
      propertyId: parseInt(id),
      taxAmount: taxAmount,
      securityDeposit: 0,
      receiptUrl: 'xyz',
      claningAmount: propertyDetail?.cleaningAndMaintenanceFee,
      paymentMethodId: 3,
      propertyPurchaseTypeId: propertyDetail?.propertyPurchaseTypeId,

    };

    const response = await MMGCheckoutPage({ jwt: MMGToken });

    if (propertyDetail?.propertyPurchaseTypeId == 2) {
      localStorage.setItem("PropertyDetails", JSON.stringify(arr));
    } else if (propertyDetail?.propertyPurchaseTypeId == 3) {
      localStorage.setItem("PropertyDetails", JSON.stringify(arr1));
    } else {
      localStorage.setItem("PropertyDetails", JSON.stringify(arr3));
    }

    if (response && response.data && response.data.redirectUrl) {
      window.open(response.data.redirectUrl, '_blank');
    } else {
      toast.error('Failed to retrieve the checkout URL. Please try again.');
    }
  }

  return (
    <>
      <Header />
      <Toaster />
      <section className="yourbooking pt-5 pb-5 overflow-hidden vh-80">
        <Container>
          <div className="">
            <Row className=" ">
              <Col lg={5} className="mb-4">
                <div className="heading border-bottom border-secondary pt-2 pb-2">
                  <h5>
                    <b>Payment</b>
                  </h5>
                </div>
                <div className="mt-4">
                  <h6 className="mb-2">
                    <b>Pay With:</b>
                  </h6>
                </div>

                {isPaypalVisible == true &&
                  <PayPalScriptProvider
                    options={{ "client-id": config.CLIENT_ID }}
                    className="pglk"
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      className="paypal-buttons"
                      onClick={disableStripe}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                      fundingSource={FUNDING.PAYPAL}
                    />
                  </PayPalScriptProvider>
                }

                {/* <button className="btn paypal-button w-100 mt-2" onClick={MMGCheckout}>MMG</button> */}
                <button className="d-flex align-items-center fw-bold justify-content-center btn paypal-button w-100 mt-2" onClick={MMGCheckout}>
                  <img src="images/mmg-logo.png" alt="" style={{ height: "25px", marginRight: "8px" }} />
                  MMG
                </button>


              </Col>
              <Col lg={2}></Col>
              <Col lg={5} className="mb-4">
                <Card>
                  <div className="paymentImg">
                    {imagesArr.length > 0 ? (
                      <img src={imagesArr[0].fileNameImage} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="px-2">
                    <div>
                      <div className="mt-4">
                        <div className="">
                          <h5 className="mb-2">
                            <b>{propertyDetail.propertyName}</b>
                          </h5>
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6>{propertyDetail.location}</h6>
                            </div>
                            {propertyDetail?.propertyPurchaseTypeId !== 2 ? (
                              <h5>
                                {totalAmount > 0 ? (
                                  <b>
                                    ${convertPrice(totalAmount.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }))} {currency}
                                  </b>
                                ) : (
                                  "Amount not available"
                                )}
                              </h5>

                            ) : (
                              ""
                            )}
                          </div>
                          {propertyDetail?.propertyPurchaseTypeId == 2 ? (
                            <div>
                              <p className="text-grey mb-0">
                                {moment(new Date()).format("YYYY-MM-DD")}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <hr />

                      {propertyDetail?.propertyPurchaseTypeId == 2 ? (
                        <>
                          <div className="">
                            <Row>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Check-in</b>
                                  </div>
                                  <p className="text-grey mb-0">{checkin}</p>
                                </div>
                              </Col>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Checkout</b>
                                  </div>
                                  <p className="text-grey mb-0">{checkout}</p>
                                </div>
                              </Col>
                              <Col lg={4} className="">
                                <div>
                                  <div>
                                    <b>Guests</b>
                                  </div>
                                  <p className="text-grey mb-0">
                                    {numberOfAdults} Adult, {numberOfChildren}{" "}
                                    Children, {numberOfInfants} Infants,{" "}
                                    {numberOfPets} Pets
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <hr />
                          <div className="mt-4 mb-1">
                            <Row>

                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Cleaning and service fee</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {convertPrice(propertyDetail?.cleaningAndMaintenanceFee
                                    ? propertyDetail?.cleaningAndMaintenanceFee
                                    : 0)} {currency}
                                </span>
                              </Col>
                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Pets Amount</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {convertPrice(parseFloat(
                                    propertyDetail?.petsPrice &&
                                      numberOfPets > 0
                                      ? parseInt(numberOfPets) *
                                      parseFloat(propertyDetail?.petsPrice)
                                      : 0
                                  ))} {currency}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Taxes</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + $
                                  {convertPrice(propertyDetail?.taxPercentage
                                    ? ((parseFloat(numberOfAdults) / 2 -
                                      parseInt(numberOfAdults / 2) >
                                      0
                                      ? parseInt(
                                        parseFloat(numberOfAdults) / 2
                                      ) + 1
                                      : parseFloat(numberOfAdults) / 2) *
                                      parseFloat(roomPrice) *
                                      totalBookingDay *
                                      parseFloat(
                                        propertyDetail?.taxPercentage
                                      )) /
                                    100
                                    : 0)} {currency}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2">
                                <h6>Security Deposit</h6>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <span className="text-grey">
                                  + ${convertPrice(propertyDetail?.securityDeposit)} {currency}
                                </span>
                              </Col>

                              <Col lg={6} sm={6} xs={6} className="mb-2 ">
                                <h5>
                                  <b>Total Cost:</b>
                                </h5>
                              </Col>
                              <Col
                                lg={6}
                                sm={6}
                                xs={6}
                                className="mb-2 text-right"
                              >
                                <h5 className="fw-bold">
                                  $

                                  {
                                    (() => {
                                      const totalGuests = parseInt(numberOfAdults) + parseInt(numberOfChildren);
                                      const roomsNeeded =
                                        Math.ceil(totalGuests / guestCountPerRoom);
                                      const baseRoomCost = parseFloat(roomsNeeded) * parseFloat(roomPrice) * parseFloat(totalBookingDay);

                                      const tax =
                                        propertyDetail?.taxPercentage
                                          ? (Math.ceil(totalGuests / 2) * roomPrice * totalBookingDay * propertyDetail.taxPercentage) / 100
                                          : 0;

                                      const cleaningFee =
                                        propertyDetail?.cleaningAndMaintenanceFee && totalGuests > 0
                                          ? parseFloat(propertyDetail.cleaningAndMaintenanceFee)
                                          : 0;

                                      const petsCost =
                                        propertyDetail?.petsPrice && numberOfPets > 0
                                          ? numberOfPets * parseFloat(propertyDetail.petsPrice)
                                          : 0;

                                      const securityDeposit = parseFloat(propertyDetail?.securityDeposit || 0);

                                      const totalAmount =
                                        baseRoomCost + tax + cleaningFee + petsCost + securityDeposit;

                                      return `${convertPrice(totalAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }))} ${currency}`;
                                    })()
                                  }

                                </h5>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Payment;
