import React, { createContext, useState, useEffect } from "react";
import config from "../coreFIles/config";
import Cookies from "js-cookie";
import { getGYDPrice } from '../Action/user.action'


const API_KEY = config.REACT_APP_RATE_EXCHANGE_API_KEY;

// Create Context
export const CurrencyContext = createContext();
const gydPrice = Cookies.get("GYDPrice") || "Not Available";
export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState(localStorage.getItem("currency") || "USD");
  const [exchangeRate, setExchangeRate] = useState(1); // Default 1 USD = 1 USD

  useEffect(() => {
    const fetchExchangeRate = async () => {
      if (currency === "GYD") {
        try {
          let response = await getGYDPrice();
          const exchangeRate = parseFloat(response.data.GYDPrice).toFixed(2);
          setExchangeRate(gydPrice || exchangeRate);
          Cookies.set("GYDPrice", exchangeRate);
        } catch (error) {
          console.error("Error fetching exchange rate:", error);
        }
      } else {
        setExchangeRate(1); // If USD is selected, keep rate as 1
      }
    };

    fetchExchangeRate();
  }, [currency]);


  // Save currency selection in localStorage
  useEffect(() => {
    localStorage.setItem("currency", currency);
  }, [currency]);

  // Function to convert price based on selected currency
  const convertPrice = (priceInUSD) => {
    console.log('exchangeRate', exchangeRate, priceInUSD);

    if (!priceInUSD || isNaN(priceInUSD)) return "0.00";
    const convertedPrice = priceInUSD * exchangeRate;

    return convertedPrice.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };


  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertPrice, exchangeRate }}>
      {children}
    </CurrencyContext.Provider>
  );
};
