import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Toaster } from "react-hot-toast";
import Header from "../../../commanComponents/usernavbar";
import UserNameAndTab from "./UserNameAndTab";
import ListMenuButton from "./listMenu";
import { getAccountExpenseHistory } from "../../../../Action/propertyOwner.action";
import "../../css/staffdashboard.css";
import "../../css/finance.css";
import { Link } from "react-router-dom";
import config from "../../../../config";
import { useParams } from "react-router-dom";

const AccountHistory = () => {
    const { id } = useParams();
    const [expenseList, setExpenseList] = useState([]);


    useEffect(() => {
        document.body.classList.add("staffbody");
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    useEffect(() => {
        fetchExpenseList();
    }, []);

    const fetchExpenseList = async () => {
        let res = await getAccountExpenseHistory({ type: 1, id: atob(id) });
        if (res.success) {
            setExpenseList(res.data || []);
        }
    };

    createTheme(
        "solarized",
        {
            text: { primary: "#000", secondary: "#2aa198" },
            background: { default: "" },
            context: { background: "#cb4b16", text: "#FFFFFF" },
            divider: { default: "#EBEBEB" },
            action: { button: "#E46422", hover: "rgba(0,0,0,.08)", disabled: "rgba(0,0,0,.12)" },
        },
        "dark"
    );

    const columns = [
        { name: "#", selector: (row, index) => index + 1, sortable: true },
        { name: "Payee", selector: (row) => row.supplierName },
        { name: "Property", selector: (row) => row.name },
        { name: "Expense on", selector: (row) => row.accountName },
        {
            name: "Total Amount",
            selector: (row) => `$${(row.expenseAmount + row.taxAmount).toFixed(2)}`,
            sortable: true,
        },
        {
            name: "Expense Date",
            selector: (row) => new Date(row.expenseDate).toLocaleDateString(),
        },
        {
            name: "Due Date",
            selector: (row) => new Date(row.dueDate).toLocaleDateString(),
        },
        {
            name: "Payment Status",
            selector: (row) => {
                const statusMap = { "0": "Pending", "1": "Paid", "2": "Unpaid", "3": "Overdue" };
                const statusColor = { "0": "orange", "1": "green", "2": "purple", "3": "red" };
                return <div style={{ color: statusColor[row.paymentStatus] }}>{statusMap[row.paymentStatus]}</div>;
            },
        },
    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div>
                                            <h4 className="mb-0 fw-bolder">Account Expense History</h4>
                                        </div>
                                        <Link to={`${config.baseurl}createaccount`}>
                                            <Button>Back</Button>
                                        </Link>
                                    </div>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div className="">
                                        {expenseList.length > 0 ? (
                                            <Row>
                                                <Col lg={12} xs={12}>
                                                    <div className="mb-4">
                                                        <DataTable columns={columns} data={expenseList} theme="solarized" />
                                                        <div style={{ display: "none" }}>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div className="text-center">
                                                <img src="images/nodatafound.svg" alt="No data found" />
                                                <h5 className="mb-1">Expense Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default AccountHistory;
