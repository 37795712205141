import Cookies from "js-cookie";
import config from "../../config";

const getLoginData = () => {
    const loginData = Cookies.get("checkStaffAccess");
    return loginData ? JSON.parse(loginData) : null;
};

const isVehicleStaff = () => {
    const loginData = getLoginData();
    return loginData?.staffFor === 1;
};

const isPropertyStaff = () => {
    const loginData = getLoginData();
    return loginData?.staffFor === 2;
};

const isSuperStaff = () => {
    const loginData = getLoginData();
    return loginData?.staffFor === 3;
};

const checkVehicleAccess = (page) => {
    if (isSuperStaff() || !isVehicleStaff()) {
        window.location.href = page;
    } else {
        return "You don't have permission to access the vehicle module!";
    }
};

const checkPropertyAccess = (page) => {
    if (isSuperStaff() || !isPropertyStaff()) {
        window.location.href = page;
    } else {
        return "You don't have permission to access the property module!";
    }
};

export { 
    isVehicleStaff, 
    isPropertyStaff, 
    isSuperStaff, 
    checkVehicleAccess, 
    checkPropertyAccess 
};
