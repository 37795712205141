import React, { useEffect, useCallback, useState } from "react";
import config from "../../config";
import Config from "../../coreFIles/config";
import { Formik } from "formik";
import "../generalCSS/userlogin.css";
import { SigninSchema } from "../../validations/signup";
import Select from "react-select";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import {
  LoginAction,
  getCountryCodeAction,
  googleLoginAction,
} from "../../Action/propertyOwner.action";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { toast, Toaster } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import axios from "axios";
import { Eye, EyeOff } from 'lucide-react';


const UserLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();
  const [loginBrowser, setloginBrowser] = useState({});
  const [countryCode, setcountryCode] = useState({
    label: "+592",
    value: "592",
  });
  const [countryoptions, setcountryoptions] = useState([]);
  const [RemeberData, setRemeberData] = useState([]);
  const [formLoad, setFormLoad] = useState(false);
  // const REDIRECT_URI = `${config.baseurl}${config.loginurluser}/profile`;
  const [userType, setUserType] = useState("User"); // new state for userType
  const [staffUniqueId, setStaffUniqueId] = useState("");
  const [staffErrors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    getCountryCodeAPI();
  }, []);

  useEffect(() => {
    var now = new Date();
    let rememberData = localStorage.getItem("loginData");
    if (rememberData) {
      rememberData = JSON.parse(rememberData);
      if (parseInt(rememberData.expiry) - now.getTime() > 0) {
        rememberData.value.remember = true;
        setRemeberData(rememberData.value);
      } else {
        localStorage.setItem("loginData", "");
      }
    }
    setFormLoad(true);
    getLocation();
  }, []);

  const WriteCookie = async (value) => {
    var now = new Date();
    var minutes = 30;
    const item = {
      value: value,
      expiry: now.getTime() + minutes * 60 * 1000,
    };
    localStorage.setItem("loginData", JSON.stringify(item));
  };

  const getCountryCodeAPI = async () => {
    let res = await getCountryCodeAction();
    let arry = [];
    // console.log('res.data.name', res.data)
    if (res.success) {
      for (let x in res.data) {
        let obj = {};
        obj.label = `+${res.data[x].phonecode}`;
        obj.value = res.data[x].phonecode;
        arry.push(obj);
      }
      setcountryoptions(arry);
    }
  };



  const getLocation = async () => {
    axios
      .get("https://api64.ipify.org?format=json")
      .then((result) => {
        axios
          .get(`https://ipinfo.io/${result.data.ip}?token=ea220bd92fff15`)
          .then((result) => {
            var userAgent = window.navigator.userAgent,
              platform = window.navigator.platform,
              macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
              windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
              iosPlatforms = ["iPhone", "iPad", "iPod"],
              os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
              os = "Mac OS";
            } else if (iosPlatforms.indexOf(platform) !== -1) {
              os = "iOS";
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
              os = "Windows";
            } else if (/Android/.test(userAgent)) {
              os = "Android";
            } else if (!os && /Linux/.test(platform)) {
              os = "Linux";
            }
            setloginBrowser({
              ip: result.data.ip,
              city: result.data.city,
              country: result.data.country,
              browsername: os,
            });
          });
      });
  };

  const responseGoogle = async (response) => {
    try {
      const res = await googleLoginAction({
        browsername: `${loginBrowser?.browsername}, ${loginBrowser?.ip}, ${loginBrowser?.city} ${loginBrowser?.country}`,
        firstName: response.givenName,
        email: response.email,
        mobileNumber: response.mobileNumber,
        registrationType: 2,
        isEmailVerify: 1,
        userType: 2,
      });

      if (res.success === true) {
        console.log(res);
        Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
        setLoginData(res.data);
        setTimeout(() => {
          if (localStorage.getItem("PropertDetail")) {
            window.location.href = `${localStorage.getItem("PropertDetail")}`;
          } else {
            window.location.href = `${config.baseurl}`;
          }
          // navigate(`${config.baseUrl}staffdashboard`);
          // window.location.href = `${config.baseurl}${config.loginurluser}/profile`;
        }, 1000);
        toast.success(res.msg);
      }
    } catch (err) {
      toast.error(err.response);
    }
  };

  const responseFacebook = async (response) => {
    try {
      const res = await googleLoginAction({
        firstName: response.first_name,
        email: response.email,
        mobileNumber: null,
        registrationType: 3,
        isEmailVerify: 1,
        userType: 1,
      });

      if (res.success === true) {
        console.log(res);
        Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
        setLoginData(res.data);
        setTimeout(() => {
          if (localStorage.getItem("PropertDetail")) {
            window.location.href = `${localStorage.getItem("PropertDetail")}`;
          } else {
            window.location.href = `${config.baseurl}`;
          }
          // navigate(`${config.baseUrl}staffdashboard`);
          // window.location.href = `${config.baseurl}${config.loginurluser}/profile`;
        }, 1000);
        toast.success(res.msg);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.response);
    }
  };

  const SubmitForm = async (formData) => {

    let hasErrors = false;
    let validationErrors = {};

    if (userType === "Staff" && !staffUniqueId) {
      validationErrors.staffUniqueId = "Staff ID is required";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }

    setisLoading(true);

    formData.browsername = `${loginBrowser?.browsername}, ${loginBrowser?.ip}, ${loginBrowser?.city} ${loginBrowser?.country}`;
    formData.countryCode = countryCode.value;
    formData.userType = userType

    if (userType === "Staff") {
      formData.staffUniqueId = staffUniqueId;
    }

    let res = await LoginAction(formData);

    if (res.success) {
      toast.success(res.msg);

      Cookies.set("loginSuccessPropertyOwner", JSON.stringify(res.data));
      Cookies.set("checkStaffAccess", JSON.stringify(res.data));


      if (formData.remember) {
        WriteCookie({
          password: formData.password,
          mobileNumber: formData?.mobileNumber,
          countryCode: formData?.countryCode,
        });
      } else {
        localStorage.setItem("loginData", "");
      }

      setTimeout(() => {
        if (localStorage.getItem("PropertDetail")) {
          window.location.href = `${localStorage.getItem("PropertDetail")}`;
        } else {
          if (res.data.loginType == 2 && res.data.staffFor == 1) {
            window.location.href = `${config.baseurl}staffdashboard`;
          }else if (res.data.loginType == 2 && res.data.staffFor == 2) {
            window.location.href = `${config.baseurl}vehicleDashboard`;
          }else if (res.data.loginType == 3) {
            // window.location.href = `${config.baseurl}financedashboard`;
          } else {
            window.location.href = `${config.baseurl}`;
          }
        }
      }, 2000);
    } else {
      toast.error(res.msg);
      setisLoading(false);
    }
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const onLogout = useCallback(() => { }, []);
  // console.log('countryCode', countryCode)
  const SelectStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#000",
      backgroundColor: state.isSelected ? "#cf5416" : "#fff",
      minWidth: "63px",
    }),

    control: (defaultStyles, state) => ({
      ...defaultStyles,
      height: "20px",
      minHeight: "30px",
      borderColor: "#E9E9E9",
      minWidth: "63px",
      borderRadius: "0",
      backgroundColor: "#E9E9E9",
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#000",
      height: "30px",
      margin: "0px",
    }),
  };

  const userTypeOptions = [
    { value: "User", label: "As a user" },
    { value: "Staff", label: "As a staff" },
  ];

  const handleUserTypeChange = (selectedOption) => {
    setUserType(selectedOption.value);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <section className="login-section  overflow-hidden vh-80 pt-0 pb-0">
        <Container fluid className="">
          <Toaster />
          <Row className=" ">
            <Col xl={7} lg={7} className="p-0">
              <img
                src="assets/images/background/login2.jpg"
                className="userbg"
              />
            </Col>
            <Col xl={5} lg={5}>
              <div className="login-container px-3 pt-5">
                <div className="login-form">
                  <div className="login-logo">
                    <Link to={`${config.baseurl}`}>
                      <img src="assets/images/logo/logo.webp" alt="logo" />
                    </Link>
                    <h3 className="pt-4 pb-4">
                      <b>LOGIN</b>
                    </h3>
                  </div>
                  {formLoad && (
                    <Formik
                      initialValues={{
                        mobileNumber: RemeberData?.mobileNumber,
                        countryCode: RemeberData?.countryCode,
                        password: RemeberData?.password,
                        remember: RemeberData?.remember,
                      }}
                      validationSchema={SigninSchema}
                      onSubmit={(values) => {
                        SubmitForm(values);
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                      }) => (
                        <>
                          <Form onSubmit={handleSubmit} autoComplete="off">
                            <Row>

                              <Col xl={12} lg={12}>
                                <div className="mb-3">
                                  <InputGroup className="reactSelect position-relative">
                                    <Form.Group
                                      className="mb-3 "
                                      onKeyPress={(event) => {
                                        if (
                                          !/^\d*[]?\d{0,1}$/.test(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                    >
                                      <Select
                                        aria-label="countryCode"
                                        name="countryCode"
                                        onBlur={handleBlur}
                                        options={countryoptions}
                                        value={countryCode}
                                        onChange={setcountryCode}
                                        labelledBy="Country Code"
                                        styles={SelectStyles}
                                      />
                                    </Form.Group>
                                    <Form.Control
                                      placeholder="Enter phone number"
                                      aria-label="mobileNumber"
                                      name="mobileNumber"
                                      onKeyPress={(event) => {
                                        if (
                                          !/^\d*[]?\d{0,1}$/.test(event.key)
                                        ) {
                                          event.preventDefault();
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      value={values.mobileNumber}
                                    />
                                  </InputGroup>
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.mobileNumber &&
                                      touched.mobileNumber && (
                                        <div>{errors.mobileNumber}</div>
                                      )}
                                  </Form.Text>
                                </div>
                              </Col>

                              {/* <Col xl={12} lg={12}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="formBasicPassword"
                                >
                                  <Form.Control
                                    type="password"
                                    placeholder="Enter password"
                                    aria-label="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                  <Form.Text
                                    id="passwordHelpBlock"
                                    className="text-danger"
                                  >
                                    {errors.password && touched.password && (
                                      <div>{errors.password}</div>
                                    )}
                                  </Form.Text>
                                </Form.Group>
                              </Col> */}

                              <Col xl={12} lg={12}>
                                <Form.Group className="mb-3" controlId="formBasicPassword" style={{ position: 'relative' }}>
                                  <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter password"
                                    aria-label="password"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                  />
                                  <span
                                    onClick={togglePasswordVisibility}
                                    style={{
                                      position: 'absolute',
                                      right: '10px',
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      cursor: 'pointer',
                                      color: '#999'
                                    }}
                                  >
                                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                  </span>
                                  <Form.Text id="passwordHelpBlock" className="text-danger">
                                    {errors.password && touched.password && <div>{errors.password}</div>}
                                  </Form.Text>
                                </Form.Group>
                              </Col>

                              <Col xl={12} lg={12}>
                                <Form.Group className="mb-3">
                                  <Form.Label>Login Type</Form.Label>
                                  <Select
                                    options={userTypeOptions}
                                    value={userTypeOptions.find(option => option.value === userType)}
                                    onChange={handleUserTypeChange}
                                    isSearchable={false}
                                  />
                                </Form.Group>
                              </Col>

                              {userType === "Staff" && (
                                <Col xl={12} lg={12}>
                                  <Form.Group className="mb-3">
                                    <Form.Label>Staff ID</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Staff ID"
                                      value={staffUniqueId}
                                      onChange={(e) => setStaffUniqueId(e.target.value)}
                                    />
                                    {staffErrors.staffUniqueId && <div className="text-danger">{staffErrors.staffUniqueId}</div>}
                                  </Form.Group>

                                </Col>
                              )}

                              <Col xl={12} lg={12}>
                                <Row className=" justify-content-between">
                                  <Col lg={6} md={6} sm={6}>
                                    <div>
                                      <Form.Group
                                        className="mb-2"
                                        controlId="formBasicCheckbox"
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          id="default-checkbox"
                                          label="Remember Me"
                                          name="remember"
                                          checked={values.remember}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.remember}
                                        />
                                      </Form.Group>
                                    </div>
                                  </Col>
                                  <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    className="text-right"
                                  >
                                    <div className="mb-2">
                                      <Link
                                        to={`${config.baseurl}userforgetpassword`}
                                        className="text-decoration-none text-black"
                                      >
                                        Forgot Password
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xl={12} lg={12}>
                                {isLoading ? (
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="rounded-5 px-4 btn btn-primary w-100"
                                  >
                                    Processing
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    type="submit"
                                    className="rounded-5 px-4 btn btn-primary w-100"
                                  >
                                    SIGN IN
                                  </Button>
                                )}
                              </Col>

                              <Col xl={12} lg={12} className="text-center">
                                <div className="social-button text-center mt-4 mb-4">
                                  {/* <GoogleOAuthProvider clientId="1018403538313-1u3n5j591qoqcq9pvt0fuvopd9irj0p0.apps.googleusercontent.com"> */}
                                  <GoogleOAuthProvider clientId={Config.GOOGLE_CLIENT_ID}>
                                    <GoogleLogin
                                      onSuccess={(credentialResponse) => {
                                        const credentialResponseDecode =
                                          jwt_decode(
                                            credentialResponse.credential
                                          );
                                        console.log(credentialResponseDecode);
                                        responseGoogle(
                                          credentialResponseDecode
                                        );
                                      }}
                                      onError={() => {
                                        console.log("Login Failed");
                                      }}
                                    />
                                    {/* <GoogleLoginButton>Continue with Google</GoogleLoginButton> */}
                                  </GoogleOAuthProvider>

                                  <LoginSocialFacebook
                                    appId={Config.REACT_APP_FB_APP_ID}
                                    onResolve={(response) => {
                                      console.log(response, "5555");
                                      if (response.data) {
                                        responseFacebook(response.data);
                                      }
                                    }}
                                    onReject={(error) => {
                                      console.log("error", error);
                                    }}
                                    className="facebookbtn"
                                  >
                                    <FacebookLoginButton />
                                  </LoginSocialFacebook>
                                  {/* <LoginSocialFacebook
                                    appId="329685213147259"
                                    fieldsProfile={
                                      "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                                    }
                                    onLoginStart={onLoginStart}
                                    onLogoutSuccess={onLogoutSuccess}
                               
                                    onResolve={({ provider, data }) => {
                                      setProvider(provider);
                                      setProfile(data);
                                    }}
                                    onReject={(err) => {
                                      console.log(err);
                                    }}
                                  >
                                    <FacebookLoginButton>
                                      {" "}
                                      <span>Continue with facebook</span>{" "}
                                    </FacebookLoginButton>
                                  </LoginSocialFacebook> */}
                                </div>
                              </Col>
                            </Row>
                          </Form>
                          <div>
                            Don't have an account?{" "}
                            <Link
                              to={`${config.baseurl}usersignup`}
                              className="text-decoration-none text-primary"
                            >
                              {" "}
                              Signup
                            </Link>
                          </div>
                        </>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default UserLogin;
