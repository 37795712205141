
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form, Dropdown, Modal, Spinner } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Link } from "react-router-dom";
import config from "../../../../config";
import Header from "../../../commanComponents/usernavbar";
import "../../css/createcheque.css";
import { addJournalEntry, getJournalSerialNo, getBankAccountList, getActiveSupplierList, getEmployeeListAction } from '../../../../Action/propertyOwner.action'
import { toast, Toaster } from "react-hot-toast";
import { FaPlus, FaArrowLeftLong } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

const AddJournalEntry = () => {
    createTheme('solarized', {
        text: {
            primary: '#000',
            secondary: '#2aa198',
        },
        background: {
            default: '',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#EBEBEB',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height

            },
        },
        headCells: {

            style: {
                minHeight: '60px',
                paddingLeft: '20px', // override the cell padding for head cells
                paddingRight: '20px',
                background: '#FFF8F4',

            },
        },
        cells: {
            style: {
                paddingLeft: '20px', // override the cell padding for data cells
                paddingRight: '20px',
            },
        },
    };

    const navigate = useNavigate();


    const columns = [
        {
            name: '#',
            selector: (row, index) => index + 1, // Serial number based on the index
            sortable: false,
            width: '8%',
        },
        {
            name: 'Account',
            selector: row => row.account,
            width: "14%"
        },
        {
            name: 'Debit',
            selector: row => row.debit,
            width: "14%"
        },
        {
            name: 'Credit',
            selector: row => row.credit,
            width: "14%"
        },
        {
            name: 'Description',
            selector: row => row.description,
            width: "15%"
        },
        {
            name: 'Name',
            selector: row => row.name,
            width: "14%"
        },
        {
            name: 'Delete',
            selector: row => row.status,
            cell: (row, index) => (
                <Button
                    variant="default"
                    className="p-0 btn-sm text-primary"
                    onClick={() => handleDelete(index)} // Pass the index to the delete handler
                >
                    <RiDeleteBin6Line size={`15px`} />
                </Button>
            ),
        },
    ];

    useEffect(() => {
        // Add the class to the body tag
        document.body.classList.add("staffbody");

        // Cleanup function to remove the class when the component is unmounted
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);


    const [formData, setFormData] = useState({
        entryNo: '',
        date: '',
        memo: '',
        description: '',
        journalEntryArr: []
    });
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [estimateError, setEstimateError] = useState({});
    const [loading, setLoading] = useState(false);
    const [estimate, setEstimate] = useState({
        account: '',
        description: "",
        debit: "",
        credit: "",
        name: "",
    });
    const [fileName, setFileName] = useState("");
    const [serialNo, setSerialNo] = useState({})
    const [accountTypeList, setAccountTypeList] = useState([])
    const [supplierList, setSupplierList] = useState([])

    useEffect(() => {
        fetchSerial()
        fetchAccountTypeList()
        fetchSupplierList()
        fetchEmployeeList()
    }, [])


    const fetchSerial = async () => {
        let res = await getJournalSerialNo()
        if (res.success) {
            if (res.data.length != []) {
                setSerialNo(res.data[0])
            } else {
                setSerialNo({ id: 0 })
            }
        }
    }

    // const fetchSupplierList = async () => {
    //     try {
    //         let res = await getActiveSupplierList({ type: 1 })
    //         if (res.success) {
    //             setSupplierList(res.data || [])
    //         }
    //     } catch (error) {

    //     }
    // }

    const fetchSupplierList = async () => {
        try {
            let res = await getActiveSupplierList({ type: 1 })
            if (res.success) {
                const suppliers = res.data.map(item => ({
                    id: item.id,
                    name: `${item.supplierName} (Supplier)`,
                    payeeType: 1,
                }));
                setSupplierList(prevPayees => [...prevPayees, ...suppliers]);
            }
        } catch (error) {

        }
    }

    const fetchEmployeeList = async () => {
        let res = await getEmployeeListAction({ type: 1 });
        if (res.success) {
            let employeeData = res.data || []
            const employees = employeeData.map(item => ({
                id: item.id,
                name: `${item.firstName + ' ' + item.lastName} (Employee)`,
                payeeType: 2,
            }));
            setSupplierList(prevPayees => [...prevPayees, ...employees]);
        }
    };

    const fetchAccountTypeList = async () => {
        let res = await getBankAccountList();
        if (res.success) {
            setAccountTypeList(res.data || []);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = ({ entryNo, date }) => {
        const errors = {};

        // if (!entryNo) {
        //     errors.entryNo = "Entry number is required";
        // }
        if (!date) {
            errors.date = "Date is required";
        }
        if (formData.journalEntryArr.length === 0) errors.journalEntryArr = "At least one item is required";


        return errors;
    };

    const validateProduct = () => {
        let estimateError = {};
        if (!estimate.account) estimateError.account = "Account is required";
        if (!estimate.debit && !estimate.credit) estimateError.amount = "Amount is required";
        // if (!estimate.credit) estimateError.credit = "Credit amount is required";
        // if (estimate.debit !== estimate.credit) estimateError.sameAmount = "Debit and credit amounts must be the same"
        // if (!estimate.description) estimateError.description = "Description is required";
        // if (!estimate.name) estimateError.name = "Name is required";
        return estimateError;
    };

    const handleDelete = (index) => {
        setFormData((prevData) => ({
            ...prevData,
            journalEntryArr: prevData.journalEntryArr.filter((_, i) => i !== index),
        }));
    };


    const handleAddProduct = () => {
        const productValidationErrors = validateProduct();
        if (Object.keys(productValidationErrors).length === 0) {
            setFormData((prevData) => ({
                ...prevData,
                journalEntryArr: [...(prevData.journalEntryArr || []), estimate],
            }));
            console.log('formData', formData)
            setEstimate({
                account: '',
                debit: '',
                credit: '',
                description: '',
                name: '',
            });
            setShowModal(false);
            setEstimateError({});
        } else {
            setEstimateError(productValidationErrors);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) return;

        if (calculateDebitAmount() != calculateCreditAmount()) {
            toast.error('Debit and credit amounts must be the same.')
            return
        }

        try {
            setLoading(true);
            let data = {
                ...formData,
                entryNo: serialNo.id + 1,
                journalEntryArr: JSON.stringify(formData.journalEntryArr),
                totalAmount: parseFloat(calculateDebitAmount() + calculateCreditAmount()).toFixed(2),
                type: 1,
            }

            const res = await addJournalEntry(data);

            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
                    navigate(`/journalentry`);
                }, 2000);
            } else {
                toast.error(res.msg);
            }
        } catch (error) {
            console.error("API error:", error);
            alert("Server Error");
        } finally {
            setLoading(false);
        }
    };

    const handleProductChange = (e) => {
        const { name, value } = e.target;
        setEstimate((prevProduct) => ({
            ...prevProduct,
            [name]: value,
        }));
    };

    const calculateDebitAmount = () => {
        return formData.journalEntryArr.reduce((total, deposite) => {
            return total + parseFloat(deposite.debit || 0);
        }, 0);
    };

    const calculateCreditAmount = () => {
        return formData.journalEntryArr.reduce((total, deposite) => {
            return total + parseFloat(deposite.credit || 0);
        }, 0);
    };

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />

            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <Row>
                        <Col lg={5} sm={12} xs={12} className="mb-4">
                            <Link to={`${config.baseurl}journalentry`}>
                                <Button variant="default" className="p-0 h-auto">
                                    <FaArrowLeftLong size={`25px`} />
                                </Button>
                            </Link>
                        </Col>
                        <Col lg={7} sm={12} xs={12} className="text-right"></Col>
                    </Row>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="mb-3 pb-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="mb-2"><h4 className="mb-0 fw-bolder">Journal Entry</h4></div>
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                            <h4 className="mb-0 fw-bolder">Debits: ${calculateDebitAmount().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                                            &nbsp;&nbsp;&nbsp;
                                            <h4 className="mb-0 fw-bolder">Credits: ${calculateCreditAmount().toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                                        </div>

                                    </div>

                                </Card.Header>

                                <Card.Body className="p-0">
                                    <div className="">
                                        <Row>
                                            <Col xl={12} lg={12} className="">
                                                <Row className="mt-2">

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group>
                                                            <Form.Label>Journal Entry No.</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="entryNo"
                                                                value={serialNo.id + 1}
                                                                isInvalid={!!errors.entryNo}
                                                                readOnly
                                                            />

                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group>
                                                            <Form.Label>Description</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="description"
                                                                value={formData.description}
                                                                onChange={handleChange}
                                                            />

                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                        <Form.Group>
                                                            <Form.Label>Date</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="date"
                                                                value={formData.date}
                                                                onChange={handleChange}
                                                                isInvalid={!!errors.date}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.date}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>

                                                    <Col lg={6} className="mb-4">
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12}>
                                                <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                            </Col>

                                            <Col xl={12} lg={12} className="">
                                                <div className="staff_upcoming_booking">
                                                    <div className="border-0">
                                                        <div className="p-0">
                                                            <div className=" mb-3">
                                                                <div className=""><h4 className="mb-0 fw-bolder">Details</h4></div>
                                                            </div>
                                                            <div>
                                                                <Row className="align-items-center">
                                                                    <Col lg={12} xs={12}>
                                                                        <div className="pt-2">
                                                                            <DataTable
                                                                                columns={columns}
                                                                                data={formData.journalEntryArr || []} // Binding to dynamic data
                                                                                customStyles={customStyles}
                                                                                theme="solarized"
                                                                            />

                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            <div className="mt-3">
                                                                <Link to={`#`}>
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                                                        onClick={() => setShowModal(true)}
                                                                    >
                                                                        Add lines
                                                                    </Button>
                                                                </Link>
                                                                <Link to={`#`}>
                                                                    <Button
                                                                        variant="primary"
                                                                        className=" rounded-5 me-2 px-4 px-xs-1 mb-4"
                                                                        onClick={() => setFormData({
                                                                            ...formData,
                                                                            journalEntryArr: []
                                                                        })}
                                                                    >
                                                                        Clear All Lines
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                            {errors.journalEntryArr && <small className="text-danger">{errors.journalEntryArr}</small>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6} className="mb-4">
                                                <Form.Group>
                                                    <Form.Label className="text-light-grey mb-1">Memo</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="memo"
                                                        value={formData.memo}
                                                        onChange={handleChange}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12}>
                                                <hr className="mb-4 mt-0" style={{ color: "#EBEBEB", opacity: "1" }} />
                                            </Col>

                                            <Col xl={12}>
                                                <div className="">

                                                    {/* ----------Balance sheet----------- */}
                                                    <div className="tableBody p-0" >
                                                        <Row className="justify-content-center">
                                                            <Col xl={4} lg={4} md={6}>
                                                                {/* ----------Image upload start-------------- */}
                                                                <div className="text-center py-sm-5 mx-auto">


                                                                    <div className="mt-5">
                                                                        <Link to={`${config.baseurl}journalentry`}>
                                                                            <Button
                                                                                variant="outline-primary"
                                                                                className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        </Link>
                                                                        <Link to={`#`}>
                                                                            <Button
                                                                                variant="primary"
                                                                                className=" rounded-5 me-2 px-4 px-xs-1 mb-4 mobileBtn"
                                                                                onClick={handleSubmit}
                                                                                disabled={loading}
                                                                            >
                                                                                {loading ? (
                                                                                    <>
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            animation="border"
                                                                                            size="sm"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                        {" "}Saving...
                                                                                    </>
                                                                                ) : (
                                                                                    "Save & Close"
                                                                                )}
                                                                            </Button>
                                                                        </Link>

                                                                    </div>
                                                                </div>
                                                                {/* ----------Image upload end-------------- */}
                                                            </Col>
                                                        </Row>



                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>


                                </Card.Body>
                            </Card>
                        </Col>
                    </div>

                </Container>
            </section>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Journal Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicExpense" className="mb-3">
                            <Form.Label>Account</Form.Label>
                            <Form.Control
                                as="select"
                                name="account"
                                value={estimate?.account}
                                onChange={handleProductChange}
                            >
                                <option value="">Select Account</option>
                                {accountTypeList.map(item => (
                                    <option key={item.id} value={item.accountName}>
                                        {item.accountName}
                                    </option>
                                ))}
                            </Form.Control>
                            {estimateError.account && (
                                <p className="text-danger">{estimateError.account}</p>
                            )}
                        </Form.Group>

                        {!estimate.credit &&
                            <Form.Group className="mb-3">
                                <Form.Label>Debit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="debit"
                                    value={estimate.debit}
                                    onChange={handleProductChange}
                                    className="rounded-5"
                                    onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }}
                                />
                                {estimateError.amount && (
                                    <p className="text-danger">{estimateError.amount}</p>
                                )}
                            </Form.Group>
                        }

                        {!estimate.debit &&
                            <Form.Group className="mb-3">
                                <Form.Label>Credit</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="credit"
                                    value={estimate.credit}
                                    onChange={handleProductChange}
                                    className="rounded-5"
                                    onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }}
                                />
                                {estimateError.amount && (
                                    <p className="text-danger">{estimateError.amount}</p>
                                )}

                                {estimateError.sameAmount && (
                                    <p className="text-danger">{estimateError.sameAmount}</p>
                                )}
                            </Form.Group>
                        }

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={estimate.description}
                                onChange={handleProductChange}
                                className="rounded-5"
                            />
                            {/* {estimateError.description && (
                                <p className="text-danger">{estimateError.description}</p>
                            )} */}
                        </Form.Group>

                        <Form.Group controlId="formBasicExpense" className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                as="select"
                                name="name"
                                value={estimate?.name}
                                onChange={handleProductChange}
                            >
                                {supplierList.length > 0 ? <option value="">Select Supplier</option> : <option value="">No supplier found</option>}
                                {supplierList.map(item => (
                                    <option key={item.id} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Button
                            variant="outline-primary"

                            className="rounded-5"
                            onClick={() => { setShowModal(false) }}
                        >
                            Cancel
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            variant="primary"
                            onClick={handleAddProduct}
                            className="rounded-5"
                        >
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddJournalEntry;
