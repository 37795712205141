import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button, Dropdown } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { Toaster } from "react-hot-toast";
import Header from "../../../commanComponents/usernavbar";
import UserNameAndTab from "./UserNameAndTab";
import ListMenuButton from "./listMenu";
import { getSubAccountListById } from "../../../../Action/propertyOwner.action";
import "../../css/staffdashboard.css";
import "../../css/finance.css";
import { Link } from "react-router-dom";
import config from "../../../../config";
import { useParams } from "react-router-dom";


const SubAccountList = () => {
    const { id } = useParams();

    const [subAccount, setSubAccount] = useState([]);


    useEffect(() => {
        document.body.classList.add("staffbody");
        return () => {
            document.body.classList.remove("staffbody");
        };
    }, []);

    useEffect(() => {
        fetchSubAccount();
    }, []);

    const fetchSubAccount = async () => {
        let ii = atob(id)
        let res = await getSubAccountListById({ type: 1, parentAccountId: ii });
        if (res.success) {
            setSubAccount(res.data || []);
        }
    };

    createTheme(
        "solarized",
        {
            text: { primary: "#000", secondary: "#2aa198" },
            background: { default: "" },
            context: { background: "#cb4b16", text: "#FFFFFF" },
            divider: { default: "#EBEBEB" },
            action: { button: "#E46422", hover: "rgba(0,0,0,.08)", disabled: "rgba(0,0,0,.12)" },
        },
        "dark"
    );

    const columns = [
        {
            name: "#",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Account Name',
            selector: row => row.accountName,
        },
        {
            name: 'Account Type',
            selector: row => row.name,
        },
        {
            name: 'Balance',
            selector: row => `$${row.openingBalance ? row.openingBalance : 0}`,
        },

        //    {
        //        name: 'Status',
        //        selector: row => (
        //            <span style={{ color: row.status === 0 ? 'green' : 'red' }}>
        //                {row.status === 0 ? 'Active' : 'Inactive'}
        //            </span>
        //        ),
        //    },

    ];

    return (
        <div className="staffdashboard bg-pink">
            <Header />
            <Toaster />
            <section className="listing pt-5 pb-5 overflow-hidden vh-80">
                <Container>
                    <UserNameAndTab />
                    <div className="listMenuButton">
                        <Col xl={12} lg={12} className="mb-3">
                            <Card className="border-0 tablistfinance">
                                <Card.Body>
                                    <ListMenuButton />
                                </Card.Body>
                            </Card>
                        </Col>
                    </div>
                    <div className="">
                        <Col lg={12} className="mb-3">
                            <Card className="border-0 bg-white p-3">
                                <Card.Header className="border-0 mb-0 pb-0">
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div>
                                            <h4 className="mb-0 fw-bolder">Sub Account List</h4>
                                        </div>
                                        <Link to={`${config.baseurl}createaccount`}>
                                            <Button>Back</Button>
                                        </Link>
                                    </div>
                                </Card.Header>
                                <Card.Body className="p-0">
                                    <div>
                                        {subAccount.length > 0 ? (
                                            <>
                                                <Row>
                                                    <Col lg={12} xs={12}>
                                                        <div className="mb-4">
                                                            <DataTable columns={columns} data={subAccount} theme="solarized" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* Sub Account Total Section */}
                                                <Row className="mt-3">
                                                    <Col lg={12} className="text-end">
                                                        <h5><strong>Sub Account Total: </strong>
                                                            {subAccount.reduce((total, acc) => total + (acc.openingBalance || 0), 0)}
                                                        </h5>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <div className="text-center">
                                                <img src="images/nodatafound.svg" alt="No data found" />
                                                <h5 className="mb-1">Expense Report</h5>
                                                <div>This report doesn’t have any data</div>
                                            </div>
                                        )}
                                    </div>
                                </Card.Body>

                            </Card>
                        </Col>
                    </div>
                </Container>
            </section>
        </div>
    );
};

export default SubAccountList;
