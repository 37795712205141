import config from '../../config'
import React, { useContext } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import '../../generalPages/generalCSS/footer.css'
import { Link } from 'react-router-dom';

import Cookies from "js-cookie";
import GoogleTranslate from '../../GoogleTranslate';
import { CurrencyContext } from '../../currencyConvertor/CurrencyContext';
const loginData = !Cookies.get("loginSuccessPropertyOwner") ? [] : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));

const Footer = () => {
  const { currency, setCurrency } = useContext(CurrencyContext);

  return (
    <>

      <footer className="bg-white pt-5 pb-2  border-top">
        <Container >
          <Row>
            <Col lg={4} className='mb-3'>
              <div className='f-logo mb-3'>
                <img src='assets/images/logo/logo.webp' />
              </div>
              <p>Bringing it all together</p>
              <div className='social-icon'>
                <ul>
                  <li><a href="https://www.facebook.com/Propertynugy" target='_blank'><img src='assets/images/icon/facebook2.svg' /></a></li>
                  <li><a href="https://twitter.com/Propertynugy" target='_blank'><img src='assets/images/icon/twitter.svg' /></a></li>
                  <li><a href="https://www.instagram.com/Propertynugy" target='_blank'><img src='assets/images/icon/instagram.svg' /></a></li>
                  <li><a href="https://www.youtube.com/@Propertynugy" target='_blank'><img src='assets/images/icon/youtube.svg' /></a></li>
                </ul>

              </div>
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={3}>
                  <div className="footer-block mt-4">
                    <ul>
                      <li>
                        <h6 className="text-primary mb-3">
                          <b>PROPERTYNU</b>
                        </h6>
                      </li>
                      <li>
                        <Link
                          to={
                            loginData?.length === 0
                              ? config.baseurl + "userlogin"
                              : config.baseurl + "dashboard/getstarted"
                          }
                        >
                          Getting started
                        </Link>
                      </li>
                      <li>
                        <Link to={`${config.baseurl}aboutUs`}>About Us</Link>
                      </li>
                      {/* <li><a href="#">Blog</a></li> */}
                    </ul>
                  </div>
                </Col>
                <Col lg={3} className="mb-3">
                  <div className="footer-block mt-4">
                    <ul>
                      <li>
                        <h6 className="text-primary mb-3">
                          <b>SUPPORT</b>
                        </h6>
                      </li>
                      <li>
                        <Link to={`${config.baseurl}contactus`}>
                          Contact Us
                        </Link>
                      </li>
                      {loginData?.id ? (
                        <li style={{ cursor: "pointer" }}>
                          <Link
                            to={`${config.baseurl}${config.loginurluser}/ticketmanagement/1`}
                          >
                            Chat support
                          </Link>
                        </li>
                      ) : (
                        <li style={{ cursor: "pointer" }}>
                          <Link to={`${config.baseurl}userlogin`}>
                            Chat support
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </Col>
                <Col lg={3} className="mb-3">
                  <div className="footer-block mt-4">
                    <ul>
                      <li>
                        <h6 className="text-primary mb-3">
                          <b>POLICIES</b>
                        </h6>
                      </li>
                      <li>
                        <Link to={`${config.baseurl}privacyPolicy`}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to={`${config.baseurl}faq`}>Faqs</Link>
                      </li>
                      <li>
                        <Link to={`${config.baseurl}termsConditions`}>
                          Terms & Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={3} className="mb-3">
                  <div className="footer-block mt-4">
                    <ul>
                      <div class="appstore d-flex flex-column-reverse justify-content-start w-100 align-items-start"><p>This website is trusted and security verified</p><img src="assets/images/ssl-Security.jpg" style={{ height: 68 }} /></div>

                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={3} className="mb-3">
              <span className="ms-xl-3">
                <GoogleTranslate />
              </span>

              <div className="dropdown">
                <select
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="p-2 profileDropdown w-100"
                  style={{ borderRadius: '2rem', background: 'white', borderColor: '#ccc' }}
                >
                  <option value="USD">USD</option>
                  <option value="GYD">GYD</option>
                </select>
              </div>
            </Col>
          </Row>
          <hr />
          <div className='copyright'>
            <Row>
              <Col lg={12} className='mb-3 text-center'>
                <div>
                  {/* <div>Copyright © 2024. Designed by : <a href='https://propertynu.com' target='_blank'> */}
                  <div>Copyright &copy; {new Date().getFullYear()} Property NU All Rights Reserved.</div>
                  </div>
              </Col>

            </Row>
          </div>
        </Container>
      </footer>
    </>

  );
};

export default Footer;
