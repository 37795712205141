import React from 'react';
import config from '../../config'
import config1 from '../../coreFIles/config'
import { Container, Nav, Navbar, Dropdown, Offcanvas, Button } from 'react-bootstrap';
import '../commanComponents/componentCSS/usernavbar.css';
import { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { getUserProfileAction, logoutAction, getPlanPurchaseDetailsAction } from "../../Action/user.action";
import { Link } from 'react-router-dom';
import {checkVehicleAccess, checkPropertyAccess } from "../../afterLoggedin/commanComponents/checkAccessibility";
import { toast, Toaster } from "react-hot-toast";

let urlParameter = window.location.pathname.split("/").pop()
const Usernavbar = () => {

  const [userProfile, setUserProfile] = useState([]);

  const loginData = !Cookies.get("loginSuccessPropertyOwner")
    ? []
    : JSON.parse(Cookies.get("loginSuccessPropertyOwner"));
  if (!loginData || loginData == "") {
    window.location.href = `${config.baseurl}`;
  }

  useEffect(() => {
    localStorage.removeItem("PropertyDetails");
    localStorage.removeItem("vehicleDetails");
  }, []);


  useEffect(() => {
    const fetchData = () => {
      getProfileAPI();
    }
    fetchData();
    const interval = setInterval(fetchData, 5000); 
    return () => {
      clearInterval(interval);
    };
  }, []);
  
  const getProfileAPI = async () => {

    let res = await getUserProfileAction();
    if (res.success) {
      setUserProfile(res.data);
     
    }
  };

  const logout = async () => {
    // await logoutAction();
    Cookies.remove("loginSuccessPropertyOwner");
    window.location.href = `${config.baseurl}`;
  };

  const redirectUrl = async (page) => {

    window.location.href = page
    setTimeout(() => {
      window.location.reload()
    }, 500);
  };

  const checkProperty = (page) => {
    let msg = checkPropertyAccess(page);
    toast.error(msg)
  };

  const checkVehicle = (page) => {
    let msg = checkVehicleAccess(page);
    toast.error(msg)
  };

  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-white pt-2 pb-2 ">
          <Container >
            <Navbar.Brand href={`${config.baseurl}`}>
              <img src='assets/images/logo/logo.webp' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img src='assets/images/logo/logo.webp' />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>

                <Nav className="justify-content-end flex-grow-1  ">
                  <Nav.Link className={urlParameter == 'propertyBooking' ? 'active' : ''} href={`${config.baseurl}${config.loginurluser}/propertyBooking`} >Property Booking</Nav.Link>
                  <Nav.Link className={urlParameter == 'vehicleBooking' ? 'active' : ''} href={`${config.baseurl}${config.loginurluser}/vehicleBooking`}>Vehicle Booking</Nav.Link>
                  <Nav.Link className={urlParameter == 'mywishlist' ? 'active' : ''} href={`${config.baseurl}${config.loginurluser}/mywishlist`}>Wishlist</Nav.Link>
                </Nav>
                <Nav className="justify-content-end flex-grow-1">
                  <Dropdown>
                    <Dropdown.Toggle variant="default" id="dropdown-basic" className=' profileDropdown'>

                      {!userProfile?.profile || userProfile?.profile == undefined || userProfile?.profile == null ?
                        <img src='assets/images/profile-img.png' width="35px" />
                        :

                        <img src={userProfile?.profile} width="35px" height="35px" style={{ objectFit: 'cover', borderRadius: '50%' }} className='' />
                      }

                      &nbsp; {userProfile?.firstName ? userProfile?.firstName : ''} {userProfile?.lastName ? userProfile?.lastName : ''}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                      <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}`}`)}>Home</Dropdown.Item>

                      <Dropdown.Item href="#" onClick={() => checkProperty(`${`${config.baseurl}staffdashboard`}`)}>Switch To Property Hosting</Dropdown.Item>

                      <Dropdown.Item href="#" onClick={() => checkVehicle(`${`${config.baseurl}vehicleDashboard`}`)}>Switch To Vehicle Hosting</Dropdown.Item>

                      {/* <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}${config.loginurluser}/propertyBooking`}`)}>Property Booking</Dropdown.Item>

                      <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}${config.loginurluser}/vehicleBooking`}`)}>Vehicle Booking</Dropdown.Item>                      

                      <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}${config.loginurluser}/mywishlist`}`)}>Wishlist</Dropdown.Item> */}

                      <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}${config.loginurluser}/profile#notification`}`)}><div className='d-flex justify-content-between align-items-center'>Notification {userProfile?.totalUnreadNotification > 0 ? <span className='msg'>{userProfile?.totalUnreadNotification}</span> : ""}</div></Dropdown.Item>

                      <Dropdown.Item href={`${config.baseurl}${config.loginurluser}/profile`}>Profile</Dropdown.Item>

                      <Dropdown.Item href="#" onClick={() => redirectUrl(`${`${config.baseurl}${config.loginurluser}/profile#login_security`}`)}>Login & Security</Dropdown.Item>

                      <Dropdown.Item href={`${config.baseurl}${config.loginurluser}/ticketmanagement/1`}>
                        <div className='d-flex justify-content-between align-items-center'>Get Help {userProfile?.totalSupportUnreadMsg > 0 ? <span className='msg'>{userProfile?.totalSupportUnreadMsg}</span> : ""}</div></Dropdown.Item>

                      <Dropdown.Item href={`${config.baseurl}${config.loginurluser}/ticketmanagement/2`}>
                        <div className='d-flex justify-content-between align-items-center'>Inbox
                          {userProfile?.totalSupportHostUnreadMsg > 0 ? <span className='inboxmsg'>{userProfile?.totalSupportHostUnreadMsg}</span> : ""}</div></Dropdown.Item>
                      {/* <Dropdown.Item href={`#`}>Revenue</Dropdown.Item> */}

                      <Dropdown.Item onClick={logout} className=''>Log Out</Dropdown.Item>
                    </Dropdown.Menu>

                  </Dropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>

  );
};

export default Usernavbar;
